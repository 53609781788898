.rights-modal {
  .modal-dialog {
    max-width: 90%;
    height: 85%;
    .modal-content,
    ng-component {
      height: 100%;
    }
    .modal-body {
      overflow: auto;
    }
  }

  .form-group {
    border-bottom: $formControlBorder thin solid;
    padding-bottom: calculateRem(15px);
    &:last-child {
      border-bottom: 0 none;
      padding-bottom: 0;
    }
  }
  .form-control {
    padding: calculateRem(2px) calculateRem(5px);
    height: auto;
  }
  select.form-control {
    padding-top: calculateRem(3px);
    padding-bottom: calculateRem(3px);
  }
  .table {
    .btn-cell,
    .btn-cell-head {
      padding: 0;
    }
    .btn-cell {
      .btn-icon {
        font-size: calculateRem(30px);
        line-height: calculateRem(30px);
        padding: calculateRem(12px) calculateRem(17px);
        opacity: 0.6;
        transition: opacity .3s ease;
        &:hover,
        &:focus,
        &:active {
          opacity: 1;
        }
      }
    }
    .btn-cell-head {
      .btn-icon {
        padding: calculateRem(10px) calculateRem(16px);
        color: $themeColor;
        transition: color .3s ease;
        &:hover,
        &:focus,
        &:active {
          color: darken($themeColor, 10%);
        }
      }
    }
  }
}