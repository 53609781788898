#links {
  .data-table {
    .url {
      width: calculateRem(350px);
      a {
        display: block;
        width: calculateRem(326px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
