/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/source-sans-pro-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('../assets/fonts/source-sans-pro-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v11-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/source-sans-pro-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url('../assets/fonts/source-sans-pro-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v11-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/source-sans-pro-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url('../assets/fonts/source-sans-pro-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v11-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/source-sans-pro-v11-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
       url('../assets/fonts/source-sans-pro-v11-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v11-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v11-latin-700italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/source-sans-pro-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('../assets/fonts/source-sans-pro-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v11-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/source-sans-pro-v11-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
       url('../assets/fonts/source-sans-pro-v11-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v11-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v11-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v11-latin-600italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
