#results-table-modal {
  .p-tabview-nav {
    border-bottom: 1px solid #dee2e6;
    li {

      border: 0 none;
      border-bottom: calculateRem(2px) transparent solid;
      padding: calculateRem(12px) calculateRem(30px);
      color: $tabItemColor;
      margin-right: calculateRem(3px);

      &:focus,
      &:hover {
        border-color: $tabItemColor;
      }

      &:active,
      &.p-highlight,
      &:active:hover,
      &:active:focus,
      &.p-highlight:hover,
      &.p-highlight:focus {
        color: $themeColor;
        border-color: $themeColor;
      }
      a {
        text-decoration: none;
      }
    }
  }
}
