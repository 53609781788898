.loader {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: calculateRem(180px);
    height: calculateRem(40px);
    margin-left: $menuWidth;
    margin-top: $headerHeight;
    .brand {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calculateRem(26px);
      bottom: 100%;
      color: $loaderDotsBg;
      text-shadow: calculateRem(1px) calculateRem(1px) calculateRem(2px) $commonShadow;
    }
    .round {
      position: absolute;
      top: calculateRem(10px);
      width: calculateRem(20px);
      height: calculateRem(20px);
      border-radius: 50%;
      background: $loaderDotsBg;
      animation-timing-function: linear;
      animation-duration: 0.6s;
      animation-iteration-count: infinite;
      box-shadow: calculateRem(1px) calculateRem(1px) calculateRem(3px) $commonShadow;
      &.round-1 {
        left: 0;
        animation-name: lds-ellipsis1;
      }
      &.middle-round {
        animation-name: lds-ellipsis2;
        z-index: 1;
      }
      &.round-2 {
        left: 0;
      }
      &.round-3 {
        left: calculateRem(40px);
      }
      &.round-4 {
        left: calculateRem(80px);
      }
      &.round-5 {
        left: calculateRem(120px);
      }
      &.round-6 {
        left: calculateRem(160px);
        animation-name: lds-ellipsis3;
      }
    }
  }

  &.menu-collapsed {
    .lds-ripple {
      margin-left: $menuCollapsedWidth;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(#{calculateRem(40px)}, 0);
  }
}
