#workflow-detail {
  h1 {
    margin-bottom: calculateRem(10px);
  }
  .description {
    color: $dataTableHeaderColor;
    font-size: calculateRem(13px);
  }
  .workflow-btn {
    width: calculateRem(182px);
  }
  .workflow-content {
    margin: 0;
  }
  .details {
    background-color: $commonBg;
    box-shadow: $defaultShadow;
    width: calculateRem(370px);
    min-width: calculateRem(370px);
    .executions-link {
      .fa {
        position: relative;
        top: calculateRem(1px);
      }
    }
    .p-tabview-panels {
      padding: calculateRem(20px) calculateRem(30px);
      height: 100%;
      overflow: auto;
    }
    .p-tabview-nav {
      padding: 0 calculateRem(30px);
      border-bottom: 1px solid #dee2e6;
      li {
        border: 0 none;
        border-bottom: calculateRem(2px) transparent solid;
        padding: calculateRem(12px) calculateRem(30px);
        color: $tabItemColor;
        margin-right: calculateRem(3px);
        &:focus,
        &:hover {
          border-color: $tabItemColor;
        }
        &:active,
        &.p-highlight,
        &:active:hover,
        &:active:focus,
        &.p-highlight:hover,
        &.p-highlight:focus {
          color: $themeColor;
          border-color: $themeColor;
        }
        a {
          text-decoration: none;
        }
      }
    }

    .version-selector {
      label {
        font-size: 1.2rem;
      }
      select {
        font-size: calculateRem(14px);
      }
    }
    hr {
      margin: calculateRem(14px) calculateRem(-30px) calculateRem(14px);
    }
    .logs-btn {
      padding: 0 calculateRem(10px) 0 0;
      span {
        font-size: calculateRem(15px);
        font-weight: bold;
        color: $textColor;
      }
      .fa {
        transform: rotate(0deg);
        transition: transform 0.3s linear, opacity 0.3s ease;
        opacity: 0.6;
        &.rotated {
          transform: rotate(180deg);
        }
      }
      &:hover,
      &:focus,
      &:active {
        .fa {
          opacity: 1;
        }
      }
    }
    .method-content {
      max-height: calculateRem(320px);
      overflow: auto;
      margin-bottom: calculateRem(30px);
      transition: max-height 0.3s linear;
      &.closed {
        max-height: 0;
      }
    }
    .add-exec-btn,
    .delete-exec-btn {
      opacity: 0.6;
      transition: opacity 0.3s ease;
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }
    .delete-exec-btn {
      color: $errorColor;
    }
    .execute-btn {
      position: relative;
      .edited-process-icon {
        position: absolute;
        top: calculateRem(2px);
        left: calculateRem(4px);
        padding: calculateRem(7px) calculateRem(7px) calculateRem(12px);
        font-size: 0.7em;
      }
    }
  }
  .process-floor {
    margin-bottom: calculateRem(40px);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .process-box {
    border: calculateRem(2px) solid transparent;
    border-radius: calculateRem(5px);
    box-shadow: 0 0 calculateRem(5px) rgba(50, 50, 50, 0.5);
    padding: calculateRem(22px) calculateRem(25px);
    background: $commonBg;
    font-size: calculateRem(13px);
    max-width: calculateRem(600px);
    position: relative;
    .edited-process-icon {
      font-size: 0.8em;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      padding: 10px;
    }
    .btn {
      font-size: calculateRem(13px);
      padding-left: calculateRem(5px);
      padding-right: calculateRem(5px);
      i {
        position: relative;
        top: calculateRem(1px);
      }
    }
    .row {
      margin-left: calculateRem(-5px);
      margin-right: calculateRem(-5px);
    }
    .col {
      padding-left: calculateRem(5px);
      padding-right: calculateRem(5px);
    }
    h3 {
      font-size: calculateRem(14px);
      margin-bottom: calculateRem(10px);
      i {
        margin-right: calculateRem(5px);
        position: relative;
        top: calculateRem(1px);
      }
    }
    &.treatment {
      h3 {
        i {
          color: $processTreatmentColor;
        }
      }
    }
    &.active {
      &.treatment {
        border-color: $processTreatmentBorder;
      }
    }
    &.alea {
      h3 {
        i {
          color: $processAleaColor;
          line-height: 0.7em;
          font-size: 1.3em;
          position: relative;
          top: calculateRem(3px);
          left: calculateRem(1px);
          margin-right: calculateRem(3px);
        }
      }
    }
    &.active {
      &.alea {
        border-color: $processAleaBorder;
      }
    }
    &.indicator {
      h3 {
        i {
          color: $processIndicatorColor;
        }
      }
    }
    &.active {
      &.indicator {
        border-color: $processIndicatorBorder;
      }
    }
  }
  @media (max-width: 1514px) {
    .col-4 .config-btn + col {
      margin-top: calculateRem(10px);
    }
  }
  .result-container {
    dt {
      padding-bottom: calculateRem(3px);
      margin-bottom: calculateRem(3px);
      border-bottom: calculateRem(2px) solid $themeColor;
    }
    .result-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        a {
          padding: calculateRem(2px) 0;
          i {
            font-size: 0.8em;
            margin-left: calculateRem(5px);
            position: relative;
            top: calculateRem(-1px);
          }
        }
      }
    }
    .add-to-project {
      font-size: inherit;
      position: relative;
      padding: 0 calculateRem(8px);
      top: calculateRem(-1px);
      @include btn-icon-states($projectIconColor, $textColor);
      .fa-plus {
        position: absolute;
        right: calculateRem(1px);
        top: calculateRem(0px);
        font-size: 0.7em;
      }
      &:before {
        content: " ";
        border: 0 rgba(0, 0, 0, 0.2) solid;
        transition: all 0.3s ease;
        position: absolute;
        top: calculateRem(-2px);
        left: calculateRem(3px);
        width: calculateRem(28px);
        height: calculateRem(28px);
        border-radius: 100%;
      }
      &:active {
        &:before {
          transition: none;
          border-width: calculateRem(14px);
          border-color: transparent;
        }
      }
    }
  }
}
