#projects {
  .data-table {
    .date {
      width: calculateRem(95px);
    }
    .owner {
      width: calculateRem(260px);
    }
    .workflow {
      width: calculateRem(115px);
    }
    .views {
      width: calculateRem(75px);
    }
    .fa-lock,
    .fa-globe-africa,
    .fa-user-friends {
      margin-right: calculateRem(10px);
      width: calculateRem(15px);
      position: relative;
      color: $projectIconColor;
    }
    .fa-globe-africa {
      top: calculateRem(1px);
    }
    .fa-user-friends {
      left: calculateRem(-2px);
    }
  }
}
