// bootstrap overrides
body {
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: calculateRem(15px);
  color: $textColor;
  overflow: hidden;
}

.btn {
  font-size: calculateRem(16px);
  outline: 0;
  &:not(:disabled):not(.disabled):active:focus,
  &:focus,
  &:active {
    box-shadow: none;
  }
  .fa,
  .far {
    margin-right: calculateRem(5px);
  }
}

.btn-primary,
.btn-default,
.btn-danger {
  padding-left: calculateRem(28px);
  padding-right: calculateRem(28px);
}

.btn-primary {
  @include btn-states($themeColor, $textInverseColor, darken($themeColor, 10%));

  font-weight: 600;
}

.btn-default {
  @include btn-states(
    $defaultBtnBg,
    $btnDefaultColor,
    darken($defaultBtnBg, 10%),
    darken($btnDefaultColor, 10%),
    $btnDefaultBorder,
    $btnDefaultBorder,
    null,
    null,
    $btnDefaultBorder
  );
}

.dropdown-toggle {
  &:after {
    @include fontawesome-like("\f107");
    height: auto;
    width: auto;
    position: relative;
    top: calculateRem(5px);
  }
}

.dropdown-menu {
  padding: 0;
  .dropdown-item,
  .dropdown-item:not([href]):not([tabindex]) {
    color: $textColor;
    &:hover,
    &:focus {
      background: $dropdownHoverBg;
      text-decoration: none;
    }
    &:active,
    &.active,
    &:active:hover,
    &:active:focus,
    &.active:hover,
    &.active:focus {
      background: $themeColor;
      color: $textInverseColor;
      text-decoration: none;
    }

    &.item-danger {
      color: $errorColor;
      &:hover,
      &:focus {
        color: $errorColor;
      }
      &:active,
      &.active,
      &:active:hover,
      &:active:focus,
      &.active:hover,
      &.active:focus {
        background: $errorColor;
        color: $textInverseColor;
      }
    }
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}

.form-control {
  height: auto;
  border: calculateRem(1px) solid $formControlBorder;
  &:focus {
    box-shadow: none;
    border-color: $formControlBorder;
  }
  &.ng-invalid {
    border-color: $errorColor;
  }
  .hidden {
    display: none;
  }
}

.ui-inputtext {
  padding: calculateRem(6px) calculateRem(12px);
}

textarea.form-control {
  resize: none;
  height: calculateRem(90px);
}

.form-check {
  padding: 0;

  .form-check-label {
    padding-left: calculateRem(30px);
    cursor: pointer;
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      width: calculateRem(22px);
      height: calculateRem(22px);
      border: $themeColor calculateRem(2px) solid;
      border-radius: calculateRem(6px);
      background: white;
    }
    &:after {
      @include fontawesome-like("\f00c");
      color: $themeColor;
      position: absolute;
      left: calculateRem(5px);
      top: calculateRem(6px);
      font-size: calculateRem(11px);
      transition: transform 0.25s ease, opacity 0.25s ease;
      transform: scale(0);
      opacity: 0;
    }
  }
  .form-check-input {
    display: none;
    &:checked + .form-check-label:after {
      transform: scale(1);
      opacity: 1;
    }
    &:disabled + .form-check-label {
      &:after {
        color: $neutralColor;
      }
      &:before {
        border-color: $neutralColor;
      }
    }
  }
}

.d-flex {
  > .h-100 {
    // firefox
    min-height: 0;
  }
}

// primeng overrides
.ui-autocomplete-multiple {
  &.form-control {
    padding-top: calculateRem(2px);
    padding-bottom: calculateRem(2px);
  }
  .ui-autocomplete-token.ui-state-highlight {
    background: $themeColor;
    color: $textInverseColor;
    border-radius: 4px;
  }
  .ui-autocomplete-multiple-container.ui-inputtext {
    padding: 0;
  }
}
.ui-autocomplete-panel {
  background: $commonBg;
  z-index: 1052 !important;
}
.ui-autocomplete-list-item {
  &:hover {
    background: $dropdownHoverBg;
  }
}
.ui-fileupload-content {
  .ui-messages-error {
    border: 0 none;
    padding: 0 calculateRem(35px) 0 0;
    margin: 0;
    .ui-messages-close {
      position: absolute;
      top: calculateRem(-4px);
      right: 0;
      font-size: calculateRem(30px);
    }
    .ui-messages-icon {
      display: none;
    }
  }
}

.ui-fileupload-buttonbar {
  padding: 0;
  .ui-fileupload-choose {
    padding: 0.5em;
    .ui-button-icon-left {
      left: 1em;
    }
  }
}

p-inputswitch {
  line-height: 0;
}

.p-inputtext {
  border-width: 0;
}

.ui-inputswitch {
  background: $greyColor;
  border-radius: 17px;
  .ui-inputswitch-slider {
    background: white;
    width: 20px;
    height: 20px;
    top: 3px;
    left: 3px;
  }
  &.ui-inputswitch-checked {
    background: $themeColor;
    .ui-inputswitch-slider {
      left: 21px;
    }
  }
  &.ui-state-disabled {
    opacity: 0.6;
  }
}

.ui-dropdown {
  .ui-dropdown-label {
    border: calculateRem(1px) $commonBorder solid;
    border-radius: calculateRem(4px);
    overflow: hidden;
    text-overflow: ellipsis;
    color: $textColor;
  }
}

.ui-dropdown-panel {
  background: $commonBg;
  border: calculateRem(1px) $commonBorder solid;
  border-radius: calculateRem(4px);
  .ui-dropdown-filter {
    line-height: 1.1em;
    border: calculateRem(1px) $commonBorder solid;
    border-radius: calculateRem(4px);
  }
}

.p-dropdown {
  column-gap: 5px;
  .p-dropdown-label {
    border: calculateRem(1px) $commonBorder solid;
    border-radius: calculateRem(4px);
    overflow: hidden;
    text-overflow: ellipsis;
    color: $textColor;
    padding: 5px;
  }
}

.p-dropdown-panel {
  background-color: $commonBg;
  border: calculateRem(1px) $commonBorder solid;
  border-radius: calculateRem(4px);
  padding: 5px;
  .p-dropdown-header {
    width: 92%;
    .p-dropdown-filter {
      line-height: 1.1em;
      border: calculateRem(1px) $commonBorder solid;
      border-radius: calculateRem(4px);
    }
    .p-dropdown-filter-icon {
      margin-left: 5px;
    }
  }
  .p-dropdown-items-wrapper {
    .p-dropdown-item-group {
      font-weight: bold;
    }
    .p-dropdown-item {
      padding-left: 5px;
    }
  }
}

.p-autocomplete {
  padding: 0.25rem;
  &.p-autocomplete-multiple {
    .p-autocomplete-multiple-container:not(.p-disabled){
      gap:0.5rem;
      &.p-focus {
        box-shadow: none;
      }
      &.p-inputtext {
        border-width: 0;
        input {
          border: 1px solid $commonBorder;
          padding: 0.25rem;
        }
      }
      .p-autocomplete-token {
        margin: 0;
      }

    }
  }
}

.ui-datepicker {
  background: $commonBg;
  width: 300px;
}

// globals styles
html,
body,
app-root,
.main,
#login {
  height: 100%;
}

a,
a:not([href]):not([tabindex]),
.link-style {
  cursor: pointer;
  color: $themeColor;
  text-decoration: none;
  transition: color 0.25s ease;
  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:active:focus {
    text-decoration: underline;
    color: darken($themeColor, 10%);
  }
  &.btn-icon {
    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      text-decoration: none;
    }
  }
}

h1 {
  font-size: calculateRem(28px);
  margin: calculateRem(3px) 0 calculateRem(31px);
}

hr {
  border-color: $separatorBorder;
}

section {
  position: relative;
}

input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

label,
legend {
  .fa-question-circle {
    font-size: 0.9em;
    padding: calculateRem(4px);
    position: relative;
    top: calculateRem(-1px);
    &:before {
      position: relative;
      top: calculateRem(1px);
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.menu-tooltip {
  margin-left: calc(#{$menuCollapsedWidth} - #{$menuWidth});
}

.nl2br {
  white-space: pre-wrap;
}

.neutral-link {
  color: $neutralLinkColor;
  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:active:focus {
    text-decoration: underline;
    color: $textColor;
  }
}

.alert {
  .fa-info-circle {
    margin-right: calculateRem(5px);
  }
  ul {
    margin: 0;
    padding: 0 0 0 calculateRem(20px);
  }
}

.title-button-container {
  margin-top: calculateRem(3px);
}

.trosace {
  position: relative;
  height: calculateRem(90px);
  min-width: calculateRem(145px);
  background: url("/assets/images/rosace.png") no-repeat center center;
  span {
    position: absolute;
  }
  .tnorth,
  .tsouth {
    width: 100%;
    left: 0;
    text-align: center;
  }
  .tnorth {
    top: 0;
  }
  .tsouth {
    bottom: 0;
  }
  .teast,
  .twest {
    top: 50%;
    margin-top: calculateRem(-11px);
  }
  .teast {
    left: 50%;
    margin-left: calculateRem(30px);
  }
  .twest {
    right: 50%;
    margin-right: calculateRem(30px);
  }
}

.main,
#login {
  padding: 0;
}

.page {
  background: $contentBg;
  > ng-component {
    height: 100%;
    min-height: 0;
  }
  .page-header {
    padding: calculateRem(21px) calculateRem(35px) 0;
  }
  .page-content {
    padding: 0 calculateRem(35px) calculateRem(35px);
    height: 100%;
    overflow: auto;
  }
}

.btn-icon {
  border: 0 none;
  background: transparent;
  font-size: 1.1rem;
  padding: calculateRem(8px) calculateRem(5px);
  &:focus {
    box-shadow: none;
  }
  .fa {
    margin: 0;
  }
}

.subcontent {
  background: $dataTableBg;
  padding: calculateRem(18px) calculateRem(20px);
  box-shadow: $defaultShadow;
  max-height: 100%;
  overflow: auto;
}

.metadata-field {
  position: relative;
  .alert-danger {
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: calculateRem(3px) calculateRem(10px);
    box-shadow: 0 calculateRem(1px) calculateRem(4px) rgba(20, 20, 20, 0.4);
    p {
      margin-bottom: 0;
    }
  }
  .col {
    .alert-danger {
      width: calc(100% - 30px);
    }
  }
  .tab-like {
    border-bottom: calculateRem(3px) transparent solid;
    position: relative;
    top: calculateRem(1px);
    cursor: pointer;
    transition: border-bottom 0.3s ease;
    &:hover,
    &:active,
    &:focus {
      border-bottom-color: $formControlBorder;
    }
    &.active {
      border-bottom-color: $themeColor;
    }
  }
  .file-tabs-container {
    border-bottom: calculateRem(1px) $formControlBorder solid;
  }
  .btn-close {
    padding: 0;
    line-height: 0;
    opacity: 0.6;
    transition: opacity 0.3s ease;
    color: $textColor;
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
    span {
      font-size: 2rem;
      line-height: 0.6rem;
    }
  }
  .file-size-error {
    color: $errorColor;
  }
}

.list-counter {
  font-size: calculateRem(15px);
  margin: calculateRem(10px) 0;
  color: $neutralColor;
}

.filter-field-container {
  .form-control {
    padding: calculateRem(1px) calculateRem(15px);
    height: auto;
    width: calculateRem(150px);
  }
}

.data-table {
  position: relative;
  .data-title {
    font-size: calculateRem(18px);
  }
  .table {
    margin-bottom: calculateRem(3px);
    th,
    td {
      font-size: calculateRem(13px);
      vertical-align: middle;
    }
    thead {
      background: $dataTableHeaderBg;
      th {
        color: $dataTableHeaderColor;
        border: 0 none;
        text-transform: uppercase;
        white-space: nowrap;
        span {
          white-space: normal;
        }
        &.sortable {
          position: relative;
          cursor: pointer;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome and Opera */
          &.text-center > span {
            position: relative;
            left: calculateRem(5px);
          }
          &:after {
            content: " ";
            position: relative;
            width: 0;
            height: 0;
            border: calculateRem(5px) transparent solid;
            left: calculateRem(12px);
          }
          &.asc:after {
            top: calculateRem(12px);
            border-top-color: $dataTableHeaderColor;
          }
          &.desc:after {
            top: calculateRem(-14px);
            border-bottom-color: $dataTableHeaderColor;
          }
        }
      }
    }
    tbody {
      td {
        color: $dataTableTextColor;
        border-top: 0 none;
        border-bottom: calculateRem(1px) solid $dataTableBorder;
        &.btn-icon-container {
          padding: 0;
          .dropdown-toggle {
            &:after {
              display: none;
            }
          }
          .dropdown-menu {
            margin-top: calculateRem(-10px);
            margin-left: calculateRem(-5px);
          }
        }
        .btn-icon {
          @include btn-icon-states($dataTableIconColor, $dataTableIconHoverColor);
          font-size: calculateRem(12px);
          width: calculateRem(35px);
          line-height: calculateRem(27px);
        }
      }
    }
  }
  .actions {
    width: calculateRem(50px);
  }
}

.pagination {
  margin: calculateRem(10px) 0 0;
  .page-link {
    color: $textColor;
    border-color: $paginationBorder;
    outline: 0;
    font-size: calculateRem(13px);
    &:active,
    &:focus,
    &:active:hover,
    &:active:focus {
      box-shadow: none;
      text-decoration: none;
      color: $textColor;
    }
  }
  .active {
    .page-link {
      background-color: $dataTableHeaderBg;
      color: $textColor;
      border-color: $paginationBorder;
    }
  }
  .disabled {
    .page-link {
      color: $disabledPaginationColor;
      border-color: $paginationBorder;
    }
  }
}

.right-panel {
  position: fixed;
  top: $headerHeight;
  right: 0;
  background: $commonBg;
  box-shadow: 0 0 calculateRem(5px) $commonShadow;
  width: calculateRem(460px);
  height: calc(100% - #{$headerHeight});
  overflow: auto;
  padding: calculateRem(20px);
  z-index: 12;
  h2 {
    font-size: calculateRem(27px);
    margin: 0 0 calculateRem(30px);
    padding-right: calculateRem(50px);
  }
  .btn.fa-times {
    position: absolute;
    top: calculateRem(8px);
    right: calculateRem(20px);
    font-size: calculateRem(40px);
    @include btn-icon-states($iconColor, $textColor);
  }
  label {
    font-size: calculateRem(16px);
  }
}

.bbox-container {
  padding: calculateRem(10px) calculateRem(15px) calculateRem(15px);
  border: calculateRem(1px) $formControlBorder solid;
  border-radius: calculateRem(4px);
  label,
  .form-control {
    font-size: calculateRem(14px);
  }
  label {
    margin-bottom: calculateRem(5px);
  }
  .form-control {
    height: auto;
    padding-top: calculateRem(3px);
    padding-bottom: calculateRem(3px);
  }
  .little-form-group {
    margin-bottom: calculateRem(5px);
  }
}

.confirm-modal {
  .modal-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .close {
    box-shadow: none;
    outline: 0;
    padding: 0 calculateRem(12px);
    font-size: calculateRem(50px);
    margin-top: calculateRem(-10px);
  }
  .modal-title {
    font-size: calculateRem(22px);
  }
}

.workflow-linkage-modal {
  label {
    white-space: nowrap;
  }
}

.results-table-modal {
  .modal-dialog {
    margin: 0;
    top: 2.5%;
    left: 2.5%;
    max-width: 95%;
    height: 95%;
    .modal-content {
      width: 100%;
      height: 100%;
      ng-component {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .modal-body {
        height: 100%;
        overflow: auto;
      }
    }
  }
}

.fa-circle-notch {
  animation-name: spinner;
  -o-animation-name: spinner;
  -ms-animation-name: spinner;
  -webkit-animation-name: spinner;
  -moz-animation-name: spinner;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

.cdk-drag {
  z-index: 200;
}
