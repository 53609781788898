#menu {
  width: $menuWidth;
  color: $textInverseColor;
  background: $menuBg;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s ease;
  .nav {
    width: $menuWidth;
  }
  .nav-link {
    border-top: 0 none;
    border-right: 0 none;
    border-bottom: 0 none;
    border-left: calculateRem(8px) solid;
    @include btn-states(
      transparent,
      $textInverseColor,
      $menuItemActiveBg,
      $textInverseColor,
      transparent,
      $menuItemActiveBorder
    );
    padding: calculateRem(18px) calculateRem(20px) calculateRem(18px) calculateRem(12px);
    text-align: left;
    width: 100%;
    &.active {
      background-color: $menuItemActiveBg;
      border-color: $menuItemActiveBorder;
    }
    .fa {
      width: calculateRem(20px);
      margin-right: calculateRem(5px);
      text-align: center;
      font-size: calculateRem(16px);
      color: $menuIconColor;
      position: relative;
      left: 0;
      transition: left 0.25s ease;
    }
    span {
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .fa-chevron-left {
      transform: rotate(0);
      transition: left 0.25s ease, transform 0.3s ease;
    }
  }
  hr {
    border-color: $menuDividerBorder;
    margin: calculateRem(10px) 0;
  }
  .app-version {
    white-space: nowrap;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    a {
      color: $textInverseColor;
      &:hover,
      &:focus,
      &:active {
        color: $textInverseColor;
      }
    }
  }

  &.collapsed {
    width: $menuCollapsedWidth;
    .nav-link {
      span {
        opacity: 0;
        visibility: hidden;
      }
      &:hover,
      &:active,
      &:focus,
      &.active {
        .fa {
          left: calculateRem(8px);
        }
      }
    }
    .fa-chevron-left {
      transform: rotate(180deg);
    }
    .app-version {
      opacity: 0;
      visibility: hidden;
    }
  }
}
