#execution-detail {
  .method-content {
    width: 20vw;
    max-height: 60vh;
    overflow: auto;
  }

  .top-button {
    padding-left: calculateRem(35px)
  }
}