// page avec un formulaire d'édition
.edit-form {
  background: $commonBg;
  padding: calculateRem(20px);
  overflow: auto;
  max-height: 100%;
  .form-separator {
    margin: calculateRem(15px) 0 calculateRem(30px);
    border-color: $formControlBorder;
  }
  .form-title {
    font-size: calculateRem(24px);
  }
}

// gestion des champs
.edit-fields-form {
  &.ng-submitted {
    .ng-invalid {
      border-color: $errorColor;
      .form-control {
        border-color: $errorColor;
      }
    }
  }
  .ng-invalid {
    border-color: $formControlBorder;
  }
  fieldset {
    position: relative;
    border: thin $formControlBorder solid;
    padding: calculateRem(15px);
    border-radius: calculateRem(3px);
    legend {
      position: absolute;
      font-size: calculateRem(16px);
      background: white;
      z-index: 1;
      width: auto;
      padding: 0 calculateRem(10px);
      top: calculateRem(-13px);
      left: calculateRem(10px);
    }
    label {
      margin-right: calculateRem(10px);
    }
    &.with-close-btn {
      .btn-close {
        top: calculateRem(-8px);
        right: calculateRem(-15px);
        background: $commonBg;
      }
    }
  }
  .form-group {
    label {
      small {
        display: block;
        color: $neutralColor;
      }
    }
    .alert {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      margin: 0;
      padding: calculateRem(5px) calculateRem(10px);
      font-size: calculateRem(14px);
      z-index: 1;
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .ui-calendar-button {
    @include btn-states($themeColor, $textInverseColor, darken($themeColor, 10%), $textInverseColor);
    height: auto;
    border: none;
    border-radius: 0 calculateRem(4px) calculateRem(4px) 0;
    position: relative;
  }
  .file-size-error {
    color: $errorColor;
  }
  .ui-fileupload-buttonbar {
    @include btn-states($themeColor, $textInverseColor, darken($themeColor, 10%));
    border-radius: calculateRem(4px);
  }
  .ui-fileupload-content {
    padding: 0 0 0 calculateRem(8px);
  }
  .ui-fileupload-files {
    word-break: break-word;
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    background: 0 none;
    padding: 0;
    text-align: center;
    width: calculateRem(30px);
    border: 0 none;
    color: $textColor;
    span {
      font-size: calculateRem(30px);
      line-height: calculateRem(20px);
      position: relative;
      top: calculateRem(-3px);
      opacity: 0.6;
      transition: opacity 0.3s ease;
    }
    &:hover,
    &:focus,
    &:active {
      span {
        opacity: 1;
      }
    }
    &.btn-row-delete {
      position: relative;
      top: calculateRem(4px);
      width: 100%;
    }
  }
  .trosace {
    position: relative;
    height: calculateRem(90px);
    min-width: calculateRem(145px);
    background: url("/assets/images/rosace.png") no-repeat center center;
    span {
      position: absolute;
    }
    .tnorth,
    .tsouth {
      width: 100%;
      left: 0;
      text-align: center;
    }
    .tnorth {
      top: 0;
    }
    .tsouth {
      bottom: 0;
    }
    .teast,
    .twest {
      top: 50%;
      margin-top: calculateRem(-11px);
    }
    .teast {
      left: 50%;
      margin-left: calculateRem(30px);
    }
    .twest {
      right: 50%;
      margin-right: calculateRem(30px);
    }
  }
  .described-extent {
    .open-btn {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      border-radius: 0 0 20px 0;
    }
  }
  .drop-files {
    .drop-zone {
      cursor: pointer;
      border: $commonBorder 2px dashed;
      background: $contentBg;
      border-radius: 5px;
      height: 150px;
      width: 100%;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      p,
      .drop-icon {
        pointer-events: none;
      }
      .drop-icon {
        border: 3px solid $commonBorder;
        background: $commonBg;
        border-radius: 5em;
        height: 5em;
        width: 5em;
      }
      .fa {
        font-size: 2.5em;
        position: relative;
        top: -3px;
      }
      &.dragover {
        background-color: $commonBg;
        border-color: $themeColor;
      }
    }
    .file-list {
      list-style-type: none;
      padding: 0;
      margin: calculateRem(10px) 0 0;
      li {
        border-bottom: thin solid $commonBorder;
        padding: 0 0 0 10px;
        &:last-child {
          border-bottom: 0 none;
        }
        &.invalid {
          color: $errorColor;
        }
        button {
          font-size: 2em;
          line-height: 0.6em;
          padding: 8px 10px;
        }
      }
    }
  }
}
