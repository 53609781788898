/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/source-sans-pro-v11-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../assets/fonts/source-sans-pro-v11-latin-regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-regular.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-regular.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-regular.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/source-sans-pro-v11-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"), url("../assets/fonts/source-sans-pro-v11-latin-italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-italic.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-italic.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-italic.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-italic.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/source-sans-pro-v11-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("../assets/fonts/source-sans-pro-v11-latin-700.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-700.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-700.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-700.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/source-sans-pro-v11-latin-700italic.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldItalic"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-700italic.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/source-sans-pro-v11-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("../assets/fonts/source-sans-pro-v11-latin-600.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-600.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-600.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-600.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-600.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/source-sans-pro-v11-latin-600italic.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro SemiBold Italic"), local("SourceSansPro-SemiBoldItalic"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.woff2") format("woff2"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.woff") format("woff"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.ttf") format("truetype"), url("../assets/fonts/source-sans-pro-v11-latin-600italic.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "multicons";
  src: url("../assets/fonts/multicons.eot?in0sj");
  src: url("../assets/fonts/multicons.eot?in0sj#iefix") format("embedded-opentype"), url("../assets/fonts/multicons.ttf?in0sj") format("truetype"), url("../assets/fonts/multicons.woff?in0sj") format("woff"), url("../assets/fonts/multicons.svg?in0sj#multicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mult {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "multicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mult-alea:before {
  content: "\e900";
}

body {
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 0.9375rem;
  color: #444;
  overflow: hidden;
}

.btn {
  font-size: 1rem;
  outline: 0;
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:focus, .btn:active {
  box-shadow: none;
}
.btn .fa,
.btn .far {
  margin-right: 0.3125rem;
}

.btn-primary,
.btn-default,
.btn-danger {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.btn-primary {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #e36e26;
  border-color: #e36e26;
  color: #fff;
  outline: 0;
  font-weight: 600;
}
.btn-primary:disabled {
  background-color: #e36e26;
  border-color: #e36e26;
  color: #fff;
}
.btn-primary:not(:disabled):focus, .btn-primary:not(:disabled):hover {
  background-color: #be5718;
  border-color: #be5718;
  color: #fff;
}
.btn-primary:not(:disabled):active, .btn-primary:not(:disabled):active:focus, .btn-primary:not(:disabled):active:hover {
  background-color: #be5718;
  border-color: #be5718;
  color: #fff;
  box-shadow: none;
}

.btn-default {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #ffffff;
  border-color: #a3a4ab;
  color: #666;
  outline: 0;
}
.btn-default:disabled {
  background-color: #ffffff;
  border-color: #a3a4ab;
  color: #666;
}
.btn-default:not(:disabled):focus, .btn-default:not(:disabled):hover {
  background-color: #e6e6e6;
  border-color: #a3a4ab;
  color: #4d4d4d;
}
.btn-default:not(:disabled):active, .btn-default:not(:disabled):active:focus, .btn-default:not(:disabled):active:hover {
  border-color: #a3a4ab;
  box-shadow: none;
}

.dropdown-toggle:after {
  content: "\f107";
  border: 0 none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  height: auto;
  width: auto;
  position: relative;
  top: 0.3125rem;
}

.dropdown-menu {
  padding: 0;
}
.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]) {
  color: #444;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):focus {
  background: #ecedee;
  text-decoration: none;
}
.dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active:hover, .dropdown-menu .dropdown-item:active:focus, .dropdown-menu .dropdown-item.active:hover, .dropdown-menu .dropdown-item.active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).active:focus {
  background: #e36e26;
  color: #fff;
  text-decoration: none;
}
.dropdown-menu .dropdown-item.item-danger,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger {
  color: #da2323;
}
.dropdown-menu .dropdown-item.item-danger:hover, .dropdown-menu .dropdown-item.item-danger:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:focus {
  color: #da2323;
}
.dropdown-menu .dropdown-item.item-danger:active, .dropdown-menu .dropdown-item.item-danger.active, .dropdown-menu .dropdown-item.item-danger:active:hover, .dropdown-menu .dropdown-item.item-danger:active:focus, .dropdown-menu .dropdown-item.item-danger.active:hover, .dropdown-menu .dropdown-item.item-danger.active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger.active,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger:active:focus,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger.active:hover,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]).item-danger.active:focus {
  background: #da2323;
  color: #fff;
}
.dropdown-menu .dropdown-item:first-child,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.dropdown-menu .dropdown-item:last-child,
.dropdown-menu .dropdown-item:not([href]):not([tabindex]):last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.form-control {
  height: auto;
  border: 0.0625rem solid #bcbccb;
}
.form-control:focus {
  box-shadow: none;
  border-color: #bcbccb;
}
.form-control.ng-invalid {
  border-color: #da2323;
}
.form-control .hidden {
  display: none;
}

.ui-inputtext {
  padding: 0.375rem 0.75rem;
}

textarea.form-control {
  resize: none;
  height: 5.625rem;
}

.form-check {
  padding: 0;
}
.form-check .form-check-label {
  padding-left: 1.875rem;
  cursor: pointer;
  position: relative;
}
.form-check .form-check-label:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.375rem;
  height: 1.375rem;
  border: #e36e26 0.125rem solid;
  border-radius: 0.375rem;
  background: white;
}
.form-check .form-check-label:after {
  content: "\f00c";
  border: 0 none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #e36e26;
  position: absolute;
  left: 0.3125rem;
  top: 0.375rem;
  font-size: 0.6875rem;
  transition: transform 0.25s ease, opacity 0.25s ease;
  transform: scale(0);
  opacity: 0;
}
.form-check .form-check-input {
  display: none;
}
.form-check .form-check-input:checked + .form-check-label:after {
  transform: scale(1);
  opacity: 1;
}
.form-check .form-check-input:disabled + .form-check-label:after {
  color: #888;
}
.form-check .form-check-input:disabled + .form-check-label:before {
  border-color: #888;
}

.d-flex > .h-100 {
  min-height: 0;
}

.ui-autocomplete-multiple.form-control {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.ui-autocomplete-multiple .ui-autocomplete-token.ui-state-highlight {
  background: #e36e26;
  color: #fff;
  border-radius: 4px;
}
.ui-autocomplete-multiple .ui-autocomplete-multiple-container.ui-inputtext {
  padding: 0;
}

.ui-autocomplete-panel {
  background: white;
  z-index: 1052 !important;
}

.ui-autocomplete-list-item:hover {
  background: #ecedee;
}

.ui-fileupload-content .ui-messages-error {
  border: 0 none;
  padding: 0 2.1875rem 0 0;
  margin: 0;
}
.ui-fileupload-content .ui-messages-error .ui-messages-close {
  position: absolute;
  top: -0.25rem;
  right: 0;
  font-size: 1.875rem;
}
.ui-fileupload-content .ui-messages-error .ui-messages-icon {
  display: none;
}

.ui-fileupload-buttonbar {
  padding: 0;
}
.ui-fileupload-buttonbar .ui-fileupload-choose {
  padding: 0.5em;
}
.ui-fileupload-buttonbar .ui-fileupload-choose .ui-button-icon-left {
  left: 1em;
}

p-inputswitch {
  line-height: 0;
}

.p-inputtext {
  border-width: 0;
}

.ui-inputswitch {
  background: #bcbccb;
  border-radius: 17px;
}
.ui-inputswitch .ui-inputswitch-slider {
  background: white;
  width: 20px;
  height: 20px;
  top: 3px;
  left: 3px;
}
.ui-inputswitch.ui-inputswitch-checked {
  background: #e36e26;
}
.ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  left: 21px;
}
.ui-inputswitch.ui-state-disabled {
  opacity: 0.6;
}

.ui-dropdown .ui-dropdown-label {
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #444;
}

.ui-dropdown-panel {
  background: white;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
}
.ui-dropdown-panel .ui-dropdown-filter {
  line-height: 1.1em;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
}

.p-dropdown {
  column-gap: 5px;
}
.p-dropdown .p-dropdown-label {
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #444;
  padding: 5px;
}

.p-dropdown-panel {
  background-color: white;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
  padding: 5px;
}
.p-dropdown-panel .p-dropdown-header {
  width: 92%;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  line-height: 1.1em;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  margin-left: 5px;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-item-group {
  font-weight: bold;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-item {
  padding-left: 5px;
}

.p-autocomplete {
  padding: 0.25rem;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled) {
  gap: 0.5rem;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-inputtext {
  border-width: 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-inputtext input {
  border: 1px solid #bcbccb;
  padding: 0.25rem;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled) .p-autocomplete-token {
  margin: 0;
}

.ui-datepicker {
  background: white;
  width: 300px;
}

html,
body,
app-root,
.main,
#login {
  height: 100%;
}

a,
a:not([href]):not([tabindex]),
.link-style {
  cursor: pointer;
  color: #e36e26;
  text-decoration: none;
  transition: color 0.25s ease;
}
a:hover, a:focus, a:active, a:active:hover, a:active:focus,
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):active,
a:not([href]):not([tabindex]):active:hover,
a:not([href]):not([tabindex]):active:focus,
.link-style:hover,
.link-style:focus,
.link-style:active,
.link-style:active:hover,
.link-style:active:focus {
  text-decoration: underline;
  color: #be5718;
}
a.btn-icon:hover, a.btn-icon:focus, a.btn-icon:active, a.btn-icon:active:hover, a.btn-icon:active:focus,
a:not([href]):not([tabindex]).btn-icon:hover,
a:not([href]):not([tabindex]).btn-icon:focus,
a:not([href]):not([tabindex]).btn-icon:active,
a:not([href]):not([tabindex]).btn-icon:active:hover,
a:not([href]):not([tabindex]).btn-icon:active:focus,
.link-style.btn-icon:hover,
.link-style.btn-icon:focus,
.link-style.btn-icon:active,
.link-style.btn-icon:active:hover,
.link-style.btn-icon:active:focus {
  text-decoration: none;
}

h1 {
  font-size: 1.75rem;
  margin: 0.1875rem 0 1.9375rem;
}

hr {
  border-color: #e0e0e0;
}

section {
  position: relative;
}

input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

label .fa-question-circle,
legend .fa-question-circle {
  font-size: 0.9em;
  padding: 0.25rem;
  position: relative;
  top: -0.0625rem;
}
label .fa-question-circle:before,
legend .fa-question-circle:before {
  position: relative;
  top: 0.0625rem;
}

.nowrap {
  white-space: nowrap;
}

.menu-tooltip {
  margin-left: calc(4.0625rem - 16.25rem);
}

.nl2br {
  white-space: pre-wrap;
}

.neutral-link {
  color: #888;
}
.neutral-link:hover, .neutral-link:focus, .neutral-link:active, .neutral-link:active:hover, .neutral-link:active:focus {
  text-decoration: underline;
  color: #444;
}

.alert .fa-info-circle {
  margin-right: 0.3125rem;
}
.alert ul {
  margin: 0;
  padding: 0 0 0 1.25rem;
}

.title-button-container {
  margin-top: 0.1875rem;
}

.trosace {
  position: relative;
  height: 5.625rem;
  min-width: 9.0625rem;
  background: url("/assets/images/rosace.png") no-repeat center center;
}
.trosace span {
  position: absolute;
}
.trosace .tnorth,
.trosace .tsouth {
  width: 100%;
  left: 0;
  text-align: center;
}
.trosace .tnorth {
  top: 0;
}
.trosace .tsouth {
  bottom: 0;
}
.trosace .teast,
.trosace .twest {
  top: 50%;
  margin-top: -0.6875rem;
}
.trosace .teast {
  left: 50%;
  margin-left: 1.875rem;
}
.trosace .twest {
  right: 50%;
  margin-right: 1.875rem;
}

.main,
#login {
  padding: 0;
}

.page {
  background: #f0f0f7;
}
.page > ng-component {
  height: 100%;
  min-height: 0;
}
.page .page-header {
  padding: 1.3125rem 2.1875rem 0;
}
.page .page-content {
  padding: 0 2.1875rem 2.1875rem;
  height: 100%;
  overflow: auto;
}

.btn-icon {
  border: 0 none;
  background: transparent;
  font-size: 1.1rem;
  padding: 0.5rem 0.3125rem;
}
.btn-icon:focus {
  box-shadow: none;
}
.btn-icon .fa {
  margin: 0;
}

.subcontent {
  background: #ffffff;
  padding: 1.125rem 1.25rem;
  box-shadow: 0 0 0.1875rem rgba(100, 100, 100, 0.1);
  max-height: 100%;
  overflow: auto;
}

.metadata-field {
  position: relative;
}
.metadata-field .alert-danger {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 0.1875rem 0.625rem;
  box-shadow: 0 0.0625rem 0.25rem rgba(20, 20, 20, 0.4);
}
.metadata-field .alert-danger p {
  margin-bottom: 0;
}
.metadata-field .col .alert-danger {
  width: calc(100% - 30px);
}
.metadata-field .tab-like {
  border-bottom: 0.1875rem transparent solid;
  position: relative;
  top: 0.0625rem;
  cursor: pointer;
  transition: border-bottom 0.3s ease;
}
.metadata-field .tab-like:hover, .metadata-field .tab-like:active, .metadata-field .tab-like:focus {
  border-bottom-color: #bcbccb;
}
.metadata-field .tab-like.active {
  border-bottom-color: #e36e26;
}
.metadata-field .file-tabs-container {
  border-bottom: 0.0625rem #bcbccb solid;
}
.metadata-field .btn-close {
  padding: 0;
  line-height: 0;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  color: #444;
}
.metadata-field .btn-close:hover, .metadata-field .btn-close:active, .metadata-field .btn-close:focus {
  opacity: 1;
}
.metadata-field .btn-close span {
  font-size: 2rem;
  line-height: 0.6rem;
}
.metadata-field .file-size-error {
  color: #da2323;
}

.list-counter {
  font-size: 0.9375rem;
  margin: 0.625rem 0;
  color: #888;
}

.filter-field-container .form-control {
  padding: 0.0625rem 0.9375rem;
  height: auto;
  width: 9.375rem;
}

.data-table {
  position: relative;
}
.data-table .data-title {
  font-size: 1.125rem;
}
.data-table .table {
  margin-bottom: 0.1875rem;
}
.data-table .table th,
.data-table .table td {
  font-size: 0.8125rem;
  vertical-align: middle;
}
.data-table .table thead {
  background: #f5f6fa;
}
.data-table .table thead th {
  color: #888;
  border: 0 none;
  text-transform: uppercase;
  white-space: nowrap;
}
.data-table .table thead th span {
  white-space: normal;
}
.data-table .table thead th.sortable {
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
               supported by Chrome and Opera */
}
.data-table .table thead th.sortable.text-center > span {
  position: relative;
  left: 0.3125rem;
}
.data-table .table thead th.sortable:after {
  content: " ";
  position: relative;
  width: 0;
  height: 0;
  border: 0.3125rem transparent solid;
  left: 0.75rem;
}
.data-table .table thead th.sortable.asc:after {
  top: 0.75rem;
  border-top-color: #888;
}
.data-table .table thead th.sortable.desc:after {
  top: -0.875rem;
  border-bottom-color: #888;
}
.data-table .table tbody td {
  color: #585858;
  border-top: 0 none;
  border-bottom: 0.0625rem solid #e0e0e0;
}
.data-table .table tbody td.btn-icon-container {
  padding: 0;
}
.data-table .table tbody td.btn-icon-container .dropdown-toggle:after {
  display: none;
}
.data-table .table tbody td.btn-icon-container .dropdown-menu {
  margin-top: -0.625rem;
  margin-left: -0.3125rem;
}
.data-table .table tbody td .btn-icon {
  transition: color 0.25s ease;
  color: #a6a6a6;
  font-size: 0.75rem;
  width: 2.1875rem;
  line-height: 1.6875rem;
}
.data-table .table tbody td .btn-icon:disabled {
  color: #a6a6a6;
}
.data-table .table tbody td .btn-icon:not(:disabled):focus, .data-table .table tbody td .btn-icon:not(:disabled):hover {
  color: #444;
}
.data-table .table tbody td .btn-icon:not(:disabled):active, .data-table .table tbody td .btn-icon:not(:disabled):active:focus, .data-table .table tbody td .btn-icon:not(:disabled):active:hover {
  color: #444;
}
.data-table .actions {
  width: 3.125rem;
}

.pagination {
  margin: 0.625rem 0 0;
}
.pagination .page-link {
  color: #444;
  border-color: #d8dbe2;
  outline: 0;
  font-size: 0.8125rem;
}
.pagination .page-link:active, .pagination .page-link:focus, .pagination .page-link:active:hover, .pagination .page-link:active:focus {
  box-shadow: none;
  text-decoration: none;
  color: #444;
}
.pagination .active .page-link {
  background-color: #f5f6fa;
  color: #444;
  border-color: #d8dbe2;
}
.pagination .disabled .page-link {
  color: #dfdfe4;
  border-color: #d8dbe2;
}

.right-panel {
  position: fixed;
  top: 4.375rem;
  right: 0;
  background: white;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.4);
  width: 28.75rem;
  height: calc(100% - 4.375rem);
  overflow: auto;
  padding: 1.25rem;
  z-index: 12;
}
.right-panel h2 {
  font-size: 1.6875rem;
  margin: 0 0 1.875rem;
  padding-right: 3.125rem;
}
.right-panel .btn.fa-times {
  position: absolute;
  top: 0.5rem;
  right: 1.25rem;
  font-size: 2.5rem;
  transition: color 0.25s ease;
  color: #bcbccb;
}
.right-panel .btn.fa-times:disabled {
  color: #bcbccb;
}
.right-panel .btn.fa-times:not(:disabled):focus, .right-panel .btn.fa-times:not(:disabled):hover {
  color: #444;
}
.right-panel .btn.fa-times:not(:disabled):active, .right-panel .btn.fa-times:not(:disabled):active:focus, .right-panel .btn.fa-times:not(:disabled):active:hover {
  color: #444;
}
.right-panel label {
  font-size: 1rem;
}

.bbox-container {
  padding: 0.625rem 0.9375rem 0.9375rem;
  border: 0.0625rem #bcbccb solid;
  border-radius: 0.25rem;
}
.bbox-container label,
.bbox-container .form-control {
  font-size: 0.875rem;
}
.bbox-container label {
  margin-bottom: 0.3125rem;
}
.bbox-container .form-control {
  height: auto;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}
.bbox-container .little-form-group {
  margin-bottom: 0.3125rem;
}

.confirm-modal .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
}
.confirm-modal .close {
  box-shadow: none;
  outline: 0;
  padding: 0 0.75rem;
  font-size: 3.125rem;
  margin-top: -0.625rem;
}
.confirm-modal .modal-title {
  font-size: 1.375rem;
}

.workflow-linkage-modal label {
  white-space: nowrap;
}

.results-table-modal .modal-dialog {
  margin: 0;
  top: 2.5%;
  left: 2.5%;
  max-width: 95%;
  height: 95%;
}
.results-table-modal .modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}
.results-table-modal .modal-dialog .modal-content ng-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.results-table-modal .modal-dialog .modal-content .modal-body {
  height: 100%;
  overflow: auto;
}

.fa-circle-notch {
  animation-name: spinner;
  -o-animation-name: spinner;
  -ms-animation-name: spinner;
  -webkit-animation-name: spinner;
  -moz-animation-name: spinner;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
.cdk-drag {
  z-index: 200;
}

.loader {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
}
.loader.visible {
  opacity: 1;
  visibility: visible;
}
.loader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 11.25rem;
  height: 2.5rem;
  margin-left: 16.25rem;
  margin-top: 4.375rem;
}
.loader .lds-ripple .brand {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.625rem;
  bottom: 100%;
  color: #ffffff;
  text-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.4);
}
.loader .lds-ripple .round {
  position: absolute;
  top: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: #ffffff;
  animation-timing-function: linear;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  box-shadow: 0.0625rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.4);
}
.loader .lds-ripple .round.round-1 {
  left: 0;
  animation-name: lds-ellipsis1;
}
.loader .lds-ripple .round.middle-round {
  animation-name: lds-ellipsis2;
  z-index: 1;
}
.loader .lds-ripple .round.round-2 {
  left: 0;
}
.loader .lds-ripple .round.round-3 {
  left: 2.5rem;
}
.loader .lds-ripple .round.round-4 {
  left: 5rem;
}
.loader .lds-ripple .round.round-5 {
  left: 7.5rem;
}
.loader .lds-ripple .round.round-6 {
  left: 10rem;
  animation-name: lds-ellipsis3;
}
.loader.menu-collapsed .lds-ripple {
  margin-left: 4.0625rem;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.5rem, 0);
  }
}
.ol-dragbox {
  border-color: #e36e26;
  background-color: rgba(255, 255, 255, 0.2);
  border-width: 0.1875rem;
}

.ol-control button {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  background-color: #3a3952;
  transition: background-color 0.25s ease;
}
.ol-control button:hover, .ol-control button:focus, .ol-control button:active {
  background-color: #43425d;
}

.ol-scale-line,
.ol-mouse-position {
  background: #3a3952;
  opacity: 0.9;
}

.ol-scale-line {
  padding: 0.375rem 0;
  width: 10.3125rem;
}
.ol-scale-line .ol-scale-line-inner {
  margin: 0 auto;
}

.ol-mouse-position {
  top: auto;
  right: auto;
  left: 11.25rem;
  bottom: 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.3125rem 0.3125rem;
  color: #fff;
  width: 11.875rem;
  text-align: center;
}

.ol-overviewmap {
  left: 0.5rem;
  bottom: 2.75rem;
}

.ol-attribution {
  right: 0.5rem;
  bottom: 3rem;
}

.ol-rotate {
  top: 4.25rem;
  right: 3.25rem;
}

.ol-zoom-to-bbox-control {
  left: 0.5rem;
  right: auto;
  top: 5rem;
}

.ol-full-screen {
  top: auto;
  right: 0.5rem;
  bottom: 0.5rem;
}
.ol-full-screen button.ol-full-screen-false:before {
  content: "\f31e";
  border: 0 none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.ol-layer-switcher-control {
  top: 4.25rem;
  right: 0.5rem;
}

.ol-layer-legend-control {
  top: 7rem;
  right: 0.5rem;
}

.ol-layer-measure-control {
  top: 9.75rem;
  right: 0.5rem;
}

.ol-layer-export-control {
  top: 12.5rem;
  right: 0.5rem;
}

.reset-points-control {
  top: 15.25rem;
  right: 0.5rem;
  z-index: 1;
}

.map-control-selector {
  z-index: 1;
  padding: 0;
}
.map-control-selector > div {
  opacity: 0.9;
  background: #3a3952;
  padding: 0.1875rem;
  padding-left: 0.3125rem;
  border-radius: 0.1875rem;
}
.map-control-selector label {
  color: #fff;
  margin: 0;
  white-space: nowrap;
}
.map-control-selector .form-control {
  height: auto;
  padding: 0 0.625rem;
  border: 0 none;
  border-radius: 0.1875rem;
}

.destination-selector {
  bottom: 0.5rem;
  left: 37.375rem;
}

.bottom-controls {
  position: absolute;
  bottom: 0.5rem;
  left: 23.625rem;
}
.bottom-controls .ol-control {
  position: relative;
  top: 0;
  left: 0;
}
.bottom-controls .ol-control + .ol-control {
  margin-left: 0.5rem;
}

.map-modal .modal-dialog {
  margin: 0;
  top: 2.5%;
  left: 2.5%;
  max-width: 95%;
  height: 95%;
}
.map-modal .modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}
.map-modal .modal-dialog .modal-content ng-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.map-modal .modal-dialog .modal-content .modal-body {
  height: 100%;
  overflow: auto;
  padding: 0.3125rem 0.3125rem 0;
}

#map-modal.no-modal-bottom {
  padding-bottom: 0.3125rem;
}
#map-modal .close {
  position: absolute;
  outline: 0;
  z-index: 5;
  right: 0;
  top: 0;
  font-size: 4rem;
  background: white;
  padding: 0 0.9375rem;
  border-bottom-left-radius: 0.25rem;
}

.map-container {
  height: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.bbox-help {
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 1.25rem;
  left: 0;
  width: 100%;
  z-index: 1;
}
.bbox-help p {
  margin: 0 auto;
  background: white;
  display: inline-block;
  padding: 0.3125rem 0.625rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.4);
}

.autocomplete {
  top: 8px;
  left: 48px;
  max-width: 300px;
  width: 45%;
  z-index: 1;
  padding: 3px;
}
.autocomplete > div {
  background: #3a3952;
  opacity: 0.9;
  padding: 3px;
  border-radius: 4px;
}
.autocomplete .btn {
  background: transparent;
  position: absolute;
  top: 6px;
  margin: 0;
  color: #444;
  opacity: 0.7;
  transition: opacity 0.25s ease;
}
.autocomplete .btn:hover, .autocomplete .btn:active, .autocomplete .btn:focus {
  background: transparent;
  opacity: 1;
}
.autocomplete .search-btn {
  left: 6px;
}
.autocomplete .reset-btn {
  right: 6px;
}
.autocomplete .form-control {
  border: 0 none;
  border-radius: 4px;
  height: auto;
  padding: 2px 30px;
}
.autocomplete .search-list {
  position: absolute;
  width: 100%;
  color: #fff;
  left: 0;
  top: 100%;
  padding: 0 3px 3px;
  margin-top: -3px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}
.autocomplete .search-list ul {
  background: #43425d;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 4px;
}
.autocomplete .search-list li a,
.autocomplete .search-list li a:not([href]):not([tabindex]) {
  display: block;
  padding: 1px 5px;
  font-size: 14px;
  color: #fff;
}
.autocomplete .search-list li a:hover, .autocomplete .search-list li a:active, .autocomplete .search-list li a:focus, .autocomplete .search-list li a:active:hover, .autocomplete .search-list li a:active:focus,
.autocomplete .search-list li a:not([href]):not([tabindex]):hover,
.autocomplete .search-list li a:not([href]):not([tabindex]):active,
.autocomplete .search-list li a:not([href]):not([tabindex]):focus,
.autocomplete .search-list li a:not([href]):not([tabindex]):active:hover,
.autocomplete .search-list li a:not([href]):not([tabindex]):active:focus {
  background: #56556e;
  color: #fff;
}
.autocomplete:hover .search-list {
  background: rgba(255, 255, 255, 0.6);
}

.layer-switcher {
  position: absolute;
  top: 4.25rem;
  right: 0;
  z-index: 10;
  overflow: auto;
  background: white;
  border: 0.0625rem #bcbccb solid;
  box-shadow: -0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.4);
  width: 50%;
  max-width: 43.75rem;
  transition: right 0.5s ease;
}
.layer-switcher .btn-icon {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.layer-switcher .btn-icon:hover, .layer-switcher .btn-icon:focus, .layer-switcher .btn-icon:active {
  opacity: 1;
}
.layer-switcher .layer-switcher-header {
  padding: 0 0.625rem;
  border-bottom: 0.125rem #bcbccb solid;
}
.layer-switcher .layer-switcher-header h4 {
  margin: 0;
}
.layer-switcher .layer-switcher-header .form-control {
  height: auto;
  width: auto;
  padding: 0.0625rem 0.625rem;
  max-width: 12.5rem;
}
.layer-switcher .layer-switcher-header label {
  margin: 0;
}
.layer-switcher .layerswitcher-section-header {
  margin: 0;
  padding: 0.3125rem 0.9375rem 0.4375rem;
  border-bottom: 0.125rem #bcbccb solid;
  font-size: 1.125rem;
}
.layer-switcher .layer-switcher-rows-container {
  max-height: 18.75rem;
  overflow: auto;
}
.layer-switcher .layer-switcher-rows-container + .layerswitcher-section-header {
  border-top: 0.125rem #bcbccb solid;
}
.layer-switcher .no-layers {
  padding: 0.1875rem 0.625rem;
}
.layer-switcher .trosace {
  position: relative;
  height: 4.75rem;
  min-width: 9.0625rem;
  background: url("/assets/images/rosace.png") no-repeat center center;
}
.layer-switcher .trosace span {
  position: absolute;
}
.layer-switcher .trosace .tnorth,
.layer-switcher .trosace .tsouth {
  width: 100%;
  left: 0;
  text-align: center;
}
.layer-switcher .trosace .tnorth {
  top: 0;
}
.layer-switcher .trosace .tsouth {
  bottom: 0;
}
.layer-switcher .trosace .teast,
.layer-switcher .trosace .twest {
  top: 50%;
  margin-top: -0.5625rem;
}
.layer-switcher .trosace .teast {
  left: 6.25rem;
}
.layer-switcher .trosace .twest {
  right: 6.25rem;
}

.layer-legends {
  top: 7rem;
  z-index: 9;
}
.layer-legends .card {
  border: 0 none;
}
.layer-legends .card-header {
  padding: 0;
}
.layer-legends .btn-link {
  color: #444;
  width: 100%;
  text-align: left;
  font-size: 0.875rem;
  white-space: normal;
  padding-right: 1.25rem;
  position: relative;
}
.layer-legends .btn-link:hover, .layer-legends .btn-link:active, .layer-legends .btn-link:focus {
  color: #444;
}
.layer-legends .btn-link:after {
  content: "\f0d7";
  border: 0 none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  right: 0.4375rem;
  top: 50%;
  margin-top: -0.3125rem;
}
.layer-legends .btn-link.collapsed:after {
  content: "\f0da";
}

.layer-measure {
  top: 9.75rem;
  max-width: 15.625rem;
  z-index: 8;
}
.layer-measure .measure-buttons .btn-container {
  background: #bcbccb;
  padding: 0.0625rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: auto;
}
.layer-measure .measure-buttons .btn {
  font-size: 1.5rem;
  width: 2.8125rem;
  opacity: 1;
  transition: color 0.25s ease, background-color 0.25s ease;
  color: #888;
  background-color: #ebebeb;
}
.layer-measure .measure-buttons .btn:focus, .layer-measure .measure-buttons .btn:hover, .layer-measure .measure-buttons .btn:active, .layer-measure .measure-buttons .btn:active:focus, .layer-measure .measure-buttons .btn:active:hover {
  color: #e36e26;
}
.layer-measure .measure-buttons .btn.active {
  background-color: #fefefe;
  color: #444;
}
.layer-measure .measure-buttons .btn-length {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
}
.layer-measure .measure-buttons .btn-area {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.layer-measure .measure-result strong {
  font-weight: 600;
}

.layer-export {
  top: 12.5rem;
  max-width: 15.625rem;
  z-index: 7;
}

.layer-switcher-row {
  padding: 0 0.625rem;
  border-bottom: 0.0625rem #bcbccb solid;
  background: white;
}
.layer-switcher-row .btn-icon {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.layer-switcher-row .btn-icon:hover, .layer-switcher-row .btn-icon:focus, .layer-switcher-row .btn-icon:active {
  opacity: 1;
}
.layer-switcher-row .btn-icon.drag-handler {
  cursor: grab;
}
.layer-switcher-row.gu-transit {
  background: #bcbccb;
  opacity: 0.5;
}
.layer-switcher-row.gu-transit * {
  visibility: hidden;
}
.layer-switcher-row.gu-mirror .btn-icon.drag-handler {
  cursor: grabbing;
}
.layer-switcher-row:last-child {
  border-bottom: 0 none;
}
.layer-switcher-row .btn-close {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6rem;
}
.layer-switcher-row .title-link,
.layer-switcher-row .title-result {
  position: relative;
  top: -0.0625rem;
  display: inline-block;
  padding: 0.1875rem 0;
}
.layer-switcher-row .title-link:not([href]):not([tabindex]):hover, .layer-switcher-row .title-link:not([href]):not([tabindex]):focus, .layer-switcher-row .title-link:not([href]):not([tabindex]):active {
  text-decoration: underline;
}
.layer-switcher-row .form-check-label {
  padding-left: 1.4375rem;
}
.layer-switcher-row .form-check-label:before {
  width: 1.25rem;
  height: 1.25rem;
}
.layer-switcher-row .form-check-label:after {
  left: 0.25rem;
  top: 0.3125rem;
}
.layer-switcher-row .slider-container {
  min-width: 3.125rem;
  position: relative;
  top: -0.1875rem;
}
.layer-switcher-row .p-slider {
  background-color: #bcbccb;
  height: 0.25rem;
  border-radius: 2px;
}
.layer-switcher-row .p-slider .p-slider-range {
  background-color: #e36e26;
}
.layer-switcher-row .p-slider .p-slider-handle {
  width: 0.875rem;
  height: 0.875rem;
  margin-left: -0.4375rem;
  margin-top: -0.4375rem;
  border-radius: 50%;
  background-color: #e36e26;
  transition: background-color 0.25s ease;
}
.layer-switcher-row .p-slider .p-slider-handle:hover {
  background-color: #be5718;
}
.layer-switcher-row .layer-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out, border-top-width 0.25s ease-out;
  border-top: 0 #bcbccb dotted;
  padding: 0 0.875rem;
  margin: 0 -0.625rem;
  font-size: 0.9em;
}
.layer-switcher-row .layer-details.open {
  max-height: 31.25rem;
  border-top-width: 0.0625rem;
  transition: max-height 0.25s ease-in;
}
.layer-switcher-row .layer-details dt {
  padding-bottom: 0.125rem;
  margin-bottom: 0.1875rem;
  border-bottom: 0.0625rem #e36e26 solid;
}
.layer-switcher-row .layer-details dt:first-child {
  padding-top: 0.3125rem;
}
.layer-switcher-row .layer-details dd:last-child {
  padding-bottom: 0.3125rem;
}
.layer-switcher-row .layer-details dd address {
  margin: 0;
}
.layer-switcher-row .layer-details dd address strong {
  font-weight: 600;
}

.result-popup {
  position: absolute;
  top: -6.5625rem;
  left: 1.125rem;
  background: #f0f0f7;
  padding: 0.9375rem 0 0.9375rem 0.9375rem;
  border-radius: 0.375rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.result-popup:before, .result-popup:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
}
.result-popup:before {
  border: transparent solid 0.625rem;
  border-right-color: rgba(0, 0, 0, 0.4);
  margin-top: -0.625rem;
  left: -1.25rem;
}
.result-popup:after {
  border: transparent solid 0.5625rem;
  border-right-color: #f0f0f7;
  margin-top: -0.5625rem;
  left: -1.125rem;
}
.result-popup dl {
  margin: 0;
  width: 12.5rem;
  height: 11.25rem;
  overflow: auto;
}

.custom-placeholder {
  background: #bcbccb;
  border: dotted 3px #9f9fb5;
  min-height: 40px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

#header {
  color: #fff;
  background: #3a3952;
  height: 4.375rem;
  padding: 0;
  z-index: 10;
}
#header .navbar {
  height: 4.375rem;
}
#header a {
  color: #fff;
}
#header .btn-icon {
  position: relative;
  top: -0.125rem;
}
#header .navbar-brand {
  margin-left: -0.9375rem;
  margin-right: 0;
  width: 16.25rem;
  line-height: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 0.9375rem;
  font-weight: 600;
  padding: 0.625rem 0;
}
#header .navbar-brand img {
  max-width: 90%;
}
#header .dropdown-menu {
  width: 100%;
}
#header .dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
  padding: 0.3125rem 1.25rem;
  color: #444;
  text-decoration: none;
}
#header .dropdown-menu .dropdown-item:active {
  background: #e36e26;
  color: #fff;
}
#header .search-group {
  position: relative;
}
#header .search-group:before {
  content: "\f002";
  border: 0 none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  left: 1.25rem;
  top: 0.5625rem;
  color: #bcbccb;
  font-size: 1.1rem;
  z-index: 10;
  pointer-events: none;
}
#header .search-group .dropdown-menu {
  margin-top: -0.125rem;
}
#header .search {
  font-size: 0.8125rem;
  padding-left: 2.8125rem;
  border: 0 none;
  min-height: 2.125rem;
}
#header .search + .input-group-append {
  margin-left: 0;
}
#header .search-btn,
#header .type-btn {
  background: #ffffff;
  color: #444;
}
#header .search-btn {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #444;
  outline: 0;
  width: 11.25rem;
  border: 0.0625rem #ffffff solid;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
#header .search-btn:disabled {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #444;
}
#header .search-btn:not(:disabled):focus, #header .search-btn:not(:disabled):hover {
  background-color: #ecedee;
  border-color: #ecedee;
  color: #444;
}
#header .search-btn:not(:disabled):active, #header .search-btn:not(:disabled):active:focus, #header .search-btn:not(:disabled):active:hover {
  background-color: #ecedee;
  border-color: #ecedee;
  color: #444;
  box-shadow: none;
}
#header .type-btn {
  position: relative;
  border: 0 none;
  width: 10.9375rem;
  text-align: left;
  font-size: 0.8125rem;
  padding: 0.3125rem 1.25rem;
}
#header .type-btn:before {
  content: " ";
  width: 0;
  height: 1.5rem;
  border-left: 0.0625rem #bcbccb solid;
  position: absolute;
  left: 0;
  top: 0.4375rem;
}
#header .type-btn:after {
  content: "\f0d7";
  opacity: 0.4;
  font-size: 0.875rem;
  position: absolute;
  right: 0.9375rem;
  top: 50%;
  margin-top: -0.375rem;
}
#header .btn-icon {
  transition: color 0.25s ease;
  color: #bcbccb;
  margin-left: 0.625rem;
}
#header .btn-icon:disabled {
  color: #bcbccb;
}
#header .btn-icon:not(:disabled):focus, #header .btn-icon:not(:disabled):hover {
  color: #fff;
}
#header .btn-icon:not(:disabled):active, #header .btn-icon:not(:disabled):active:focus, #header .btn-icon:not(:disabled):active:hover {
  color: #fff;
}
#header .fa-bell {
  position: relative;
}
#header .fa-bell:after {
  content: " ";
  display: none;
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem #3a3952 solid;
  border-radius: 0.625rem;
  position: absolute;
  top: -0.3125rem;
  right: -0.3125rem;
}
#header .fa-bell.alerts:after, #header .fa-bell.completed:after {
  display: block;
}
#header .fa-bell.alerts:after {
  background: #e36e26;
}
#header .fa-bell.completed:after {
  background: #00ad0d;
}
#header .calls-item .dropdown-toggle:after {
  font-size: 1rem;
  margin-left: 0.625rem;
}
#header .calls-item .dropdown-menu {
  font-size: 0.875rem;
  min-width: 18.75rem;
  width: auto;
}
#header .calls-item .dropdown-menu p {
  padding: 0.3125rem 1.25rem;
  font-weight: 600;
}
#header .calls-item .dropdown-menu .dropdown-item .status {
  display: inline-block;
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 100%;
  margin-right: 0.1875rem;
  position: relative;
  top: 0.0625rem;
  border: 0.0625rem #ffffff solid;
}
#header .calls-item .dropdown-menu .dropdown-item .status.Submitted, #header .calls-item .dropdown-menu .dropdown-item .status.Running {
  background: #e36e26;
}
#header .calls-item .dropdown-menu .dropdown-item .status.Succeeded {
  background: #00ad0d;
}
#header .calls-item .dropdown-menu .dropdown-item .status.Failed {
  background: #da2323;
}
#header .separator {
  position: relative;
  padding-left: 0.625rem;
  margin-left: 0.625rem;
}
#header .separator:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1.75rem;
  width: 0;
  margin-top: -0.9375rem;
  border-left: 0.0625rem #fff solid;
}
#header .account {
  padding-right: 3.125rem;
  position: relative;
  font-size: 0.8125rem;
}
#header .account:after {
  color: #bcbccb;
  margin-left: 0.4375rem;
  top: 0.4375rem;
  line-height: 0.1rem;
  font-size: 1.0625rem;
}
#header .account .fa-user {
  position: absolute;
  right: 0;
  font-size: 1.875rem;
  overflow: hidden;
  border-radius: 100%;
  width: 2.375rem;
  top: 0;
  height: 2.375rem;
  text-align: center;
  background: #c5c5c5;
}
#header .account .fa-user:before {
  position: relative;
  top: 0.625rem;
}
#header .account + .dropdown-menu {
  margin-top: 0.625rem;
}
#header .account + .dropdown-menu .groups-container {
  margin: 0;
  padding: 0.3125rem 1.25rem;
  font-size: 0.9em;
  border-bottom: thin solid #bcbccb;
}
#header .account + .dropdown-menu .groups-container dd {
  margin: 0;
}
#header .account + .dropdown-menu .groups-container ul {
  padding-left: 1.1875rem;
  list-style-type: circle;
  margin: 0;
}

#menu {
  width: 16.25rem;
  color: #fff;
  background: #43425d;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s ease;
}
#menu .nav {
  width: 16.25rem;
}
#menu .nav-link {
  border-top: 0 none;
  border-right: 0 none;
  border-bottom: 0 none;
  border-left: 0.5rem solid;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  outline: 0;
  padding: 1.125rem 1.25rem 1.125rem 0.75rem;
  text-align: left;
  width: 100%;
}
#menu .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
#menu .nav-link:not(:disabled):focus, #menu .nav-link:not(:disabled):hover {
  background-color: #56556e;
  border-color: #e36e26;
  color: #fff;
}
#menu .nav-link:not(:disabled):active, #menu .nav-link:not(:disabled):active:focus, #menu .nav-link:not(:disabled):active:hover {
  background-color: #56556e;
  border-color: #56556e;
  color: #fff;
  box-shadow: none;
}
#menu .nav-link.active {
  background-color: #56556e;
  border-color: #e36e26;
}
#menu .nav-link .fa {
  width: 1.25rem;
  margin-right: 0.3125rem;
  text-align: center;
  font-size: 1rem;
  color: #a5a4bf;
  position: relative;
  left: 0;
  transition: left 0.25s ease;
}
#menu .nav-link span {
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
#menu .nav-link .fa-chevron-left {
  transform: rotate(0);
  transition: left 0.25s ease, transform 0.3s ease;
}
#menu hr {
  border-color: #707070;
  margin: 0.625rem 0;
}
#menu .app-version {
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
#menu .app-version a {
  color: #fff;
}
#menu .app-version a:hover, #menu .app-version a:focus, #menu .app-version a:active {
  color: #fff;
}
#menu.collapsed {
  width: 4.0625rem;
}
#menu.collapsed .nav-link span {
  opacity: 0;
  visibility: hidden;
}
#menu.collapsed .nav-link:hover .fa, #menu.collapsed .nav-link:active .fa, #menu.collapsed .nav-link:focus .fa, #menu.collapsed .nav-link.active .fa {
  left: 0.5rem;
}
#menu.collapsed .fa-chevron-left {
  transform: rotate(180deg);
}
#menu.collapsed .app-version {
  opacity: 0;
  visibility: hidden;
}

.breadcrumb {
  background-color: white;
  margin: 0;
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem rgba(100, 100, 100, 0.1);
  position: relative;
  z-index: 10;
}
.breadcrumb .breadcrumb-item {
  font-size: 0.8125rem;
}
.breadcrumb .breadcrumb-item:before, .breadcrumb .breadcrumb-item.active {
  color: #a6a7ad;
}

.simple-map {
  width: 100%;
  height: 15.625rem;
  border: 0.0625rem #bcbccb solid;
}

#projects .data-table .date {
  width: 5.9375rem;
}
#projects .data-table .owner {
  width: 16.25rem;
}
#projects .data-table .workflow {
  width: 7.1875rem;
}
#projects .data-table .views {
  width: 4.6875rem;
}
#projects .data-table .fa-lock,
#projects .data-table .fa-globe-africa,
#projects .data-table .fa-user-friends {
  margin-right: 0.625rem;
  width: 0.9375rem;
  position: relative;
  color: #888;
}
#projects .data-table .fa-globe-africa {
  top: 0.0625rem;
}
#projects .data-table .fa-user-friends {
  left: -0.125rem;
}

#links .data-table .url {
  width: 21.875rem;
}
#links .data-table .url a {
  display: block;
  width: 20.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#project-detail h1 {
  margin-bottom: 0.625rem;
}
#project-detail h1 .fa {
  color: #888;
  margin-right: 0.625rem;
  font-size: 0.9em;
}
#project-detail .description {
  color: #888;
  font-size: 0.8125rem;
  margin: 0;
}
#project-detail .subcontent {
  padding: 0;
}
#project-detail .p-tabview-panels {
  border-color: #e9e9f0;
  padding: 0;
  position: relative;
  height: 100%;
}
#project-detail .p-tabview-panels .tab-pane {
  height: 100%;
}
#project-detail .p-tabview-panels .add-btn {
  position: absolute;
  bottom: 100%;
  right: 0.4375rem;
  padding: 0.3125rem 1.5625rem;
  margin-bottom: 0.4375rem;
}
#project-detail .p-tabview-nav {
  border-bottom: 1px solid #dee2e6;
}
#project-detail .p-tabview-nav li {
  border: 0 none;
  border-bottom: 0.125rem transparent solid;
  padding: 0.75rem 1.875rem;
  color: #666;
  margin-right: 0.1875rem;
}
#project-detail .p-tabview-nav li:focus, #project-detail .p-tabview-nav li:hover {
  border-color: #666;
}
#project-detail .p-tabview-nav li:active, #project-detail .p-tabview-nav li.p-highlight, #project-detail .p-tabview-nav li:active:hover, #project-detail .p-tabview-nav li:active:focus, #project-detail .p-tabview-nav li.p-highlight:hover, #project-detail .p-tabview-nav li.p-highlight:focus {
  color: #e36e26;
  border-color: #e36e26;
}
#project-detail .p-tabview-nav li a {
  text-decoration: none;
}
#project-detail .data-table {
  margin: 0 -1rem;
  overflow: auto;
}
#project-detail .data-table .actions {
  width: 3.125rem;
}
#project-detail .data-table .url {
  width: 21.875rem;
}
#project-detail .data-table .url a {
  display: block;
  width: 20.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#project-detail .title-button-container button {
  white-space: nowrap;
}
#project-detail .meta-detail {
  overflow: auto;
}

#workflow-detail h1 {
  margin-bottom: 0.625rem;
}
#workflow-detail .description {
  color: #888;
  font-size: 0.8125rem;
}
#workflow-detail .workflow-btn {
  width: 11.375rem;
}
#workflow-detail .workflow-content {
  margin: 0;
}
#workflow-detail .details {
  background-color: white;
  box-shadow: 0 0 0.1875rem rgba(100, 100, 100, 0.1);
  width: 23.125rem;
  min-width: 23.125rem;
}
#workflow-detail .details .executions-link .fa {
  position: relative;
  top: 0.0625rem;
}
#workflow-detail .details .p-tabview-panels {
  padding: 1.25rem 1.875rem;
  height: 100%;
  overflow: auto;
}
#workflow-detail .details .p-tabview-nav {
  padding: 0 1.875rem;
  border-bottom: 1px solid #dee2e6;
}
#workflow-detail .details .p-tabview-nav li {
  border: 0 none;
  border-bottom: 0.125rem transparent solid;
  padding: 0.75rem 1.875rem;
  color: #666;
  margin-right: 0.1875rem;
}
#workflow-detail .details .p-tabview-nav li:focus, #workflow-detail .details .p-tabview-nav li:hover {
  border-color: #666;
}
#workflow-detail .details .p-tabview-nav li:active, #workflow-detail .details .p-tabview-nav li.p-highlight, #workflow-detail .details .p-tabview-nav li:active:hover, #workflow-detail .details .p-tabview-nav li:active:focus, #workflow-detail .details .p-tabview-nav li.p-highlight:hover, #workflow-detail .details .p-tabview-nav li.p-highlight:focus {
  color: #e36e26;
  border-color: #e36e26;
}
#workflow-detail .details .p-tabview-nav li a {
  text-decoration: none;
}
#workflow-detail .details .version-selector label {
  font-size: 1.2rem;
}
#workflow-detail .details .version-selector select {
  font-size: 0.875rem;
}
#workflow-detail .details hr {
  margin: 0.875rem -1.875rem 0.875rem;
}
#workflow-detail .details .logs-btn {
  padding: 0 0.625rem 0 0;
}
#workflow-detail .details .logs-btn span {
  font-size: 0.9375rem;
  font-weight: bold;
  color: #444;
}
#workflow-detail .details .logs-btn .fa {
  transform: rotate(0deg);
  transition: transform 0.3s linear, opacity 0.3s ease;
  opacity: 0.6;
}
#workflow-detail .details .logs-btn .fa.rotated {
  transform: rotate(180deg);
}
#workflow-detail .details .logs-btn:hover .fa, #workflow-detail .details .logs-btn:focus .fa, #workflow-detail .details .logs-btn:active .fa {
  opacity: 1;
}
#workflow-detail .details .method-content {
  max-height: 20rem;
  overflow: auto;
  margin-bottom: 1.875rem;
  transition: max-height 0.3s linear;
}
#workflow-detail .details .method-content.closed {
  max-height: 0;
}
#workflow-detail .details .add-exec-btn,
#workflow-detail .details .delete-exec-btn {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
#workflow-detail .details .add-exec-btn:hover, #workflow-detail .details .add-exec-btn:focus, #workflow-detail .details .add-exec-btn:active,
#workflow-detail .details .delete-exec-btn:hover,
#workflow-detail .details .delete-exec-btn:focus,
#workflow-detail .details .delete-exec-btn:active {
  opacity: 1;
}
#workflow-detail .details .delete-exec-btn {
  color: #da2323;
}
#workflow-detail .details .execute-btn {
  position: relative;
}
#workflow-detail .details .execute-btn .edited-process-icon {
  position: absolute;
  top: 0.125rem;
  left: 0.25rem;
  padding: 0.4375rem 0.4375rem 0.75rem;
  font-size: 0.7em;
}
#workflow-detail .process-floor {
  margin-bottom: 2.5rem;
}
#workflow-detail .process-floor:last-child {
  margin-bottom: 0;
}
#workflow-detail .process-box {
  border: 0.125rem solid transparent;
  border-radius: 0.3125rem;
  box-shadow: 0 0 0.3125rem rgba(50, 50, 50, 0.5);
  padding: 1.375rem 1.5625rem;
  background: white;
  font-size: 0.8125rem;
  max-width: 37.5rem;
  position: relative;
}
#workflow-detail .process-box .edited-process-icon {
  font-size: 0.8em;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 10px;
}
#workflow-detail .process-box .btn {
  font-size: 0.8125rem;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}
#workflow-detail .process-box .btn i {
  position: relative;
  top: 0.0625rem;
}
#workflow-detail .process-box .row {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}
#workflow-detail .process-box .col {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}
#workflow-detail .process-box h3 {
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
}
#workflow-detail .process-box h3 i {
  margin-right: 0.3125rem;
  position: relative;
  top: 0.0625rem;
}
#workflow-detail .process-box.treatment h3 i {
  color: #a8a6fb;
}
#workflow-detail .process-box.active.treatment {
  border-color: #7c7cb5;
}
#workflow-detail .process-box.alea h3 i {
  color: #ff7700;
  line-height: 0.7em;
  font-size: 1.3em;
  position: relative;
  top: 0.1875rem;
  left: 0.0625rem;
  margin-right: 0.1875rem;
}
#workflow-detail .process-box.active.alea {
  border-color: #e36e26;
}
#workflow-detail .process-box.indicator h3 i {
  color: #ff7285;
}
#workflow-detail .process-box.active.indicator {
  border-color: #b55160;
}
@media (max-width: 1514px) {
  #workflow-detail .col-4 .config-btn + col {
    margin-top: 0.625rem;
  }
}
#workflow-detail .result-container dt {
  padding-bottom: 0.1875rem;
  margin-bottom: 0.1875rem;
  border-bottom: 0.125rem solid #e36e26;
}
#workflow-detail .result-container .result-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#workflow-detail .result-container .result-list li a {
  padding: 0.125rem 0;
}
#workflow-detail .result-container .result-list li a i {
  font-size: 0.8em;
  margin-left: 0.3125rem;
  position: relative;
  top: -0.0625rem;
}
#workflow-detail .result-container .add-to-project {
  font-size: inherit;
  position: relative;
  padding: 0 0.5rem;
  top: -0.0625rem;
  transition: color 0.25s ease;
  color: #888;
}
#workflow-detail .result-container .add-to-project:disabled {
  color: #888;
}
#workflow-detail .result-container .add-to-project:not(:disabled):focus, #workflow-detail .result-container .add-to-project:not(:disabled):hover {
  color: #444;
}
#workflow-detail .result-container .add-to-project:not(:disabled):active, #workflow-detail .result-container .add-to-project:not(:disabled):active:focus, #workflow-detail .result-container .add-to-project:not(:disabled):active:hover {
  color: #444;
}
#workflow-detail .result-container .add-to-project .fa-plus {
  position: absolute;
  right: 0.0625rem;
  top: 0rem;
  font-size: 0.7em;
}
#workflow-detail .result-container .add-to-project:before {
  content: " ";
  border: 0 rgba(0, 0, 0, 0.2) solid;
  transition: all 0.3s ease;
  position: absolute;
  top: -0.125rem;
  left: 0.1875rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 100%;
}
#workflow-detail .result-container .add-to-project:active:before {
  transition: none;
  border-width: 0.875rem;
  border-color: transparent;
}

#execution-detail .method-content {
  width: 20vw;
  max-height: 60vh;
  overflow: auto;
}
#execution-detail .top-button {
  padding-left: 2.1875rem;
}

#account .def-line dt,
#account .def-line dd {
  display: inline-block;
}
#account .group-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}
#account .group-list li {
  line-height: 1.1em;
}
#account .group-list li strong {
  font-size: 1.1em;
  line-height: 1.2em;
}
#account .group-list li:last-child p {
  margin-bottom: 0;
}

#resources .ol-zoom-extent {
  margin-top: 0.625rem;
}
#resources .ol-zoom-extent .fa {
  position: relative;
  top: 0.0625rem;
}
#resources .destination-selector {
  bottom: 2.625rem;
  left: 0.5rem;
}
#resources .right-panel {
  width: 31.25rem;
  right: -31.25rem;
  transition: right 0.15s linear, width 0.15s linear;
  overflow: visible;
}
#resources .right-panel:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  width: 0.625rem;
  z-index: 1;
}
#resources .right-panel .toggle-btn {
  position: absolute;
  top: 4.5rem;
  right: 100%;
  background: white;
  border-radius: 0;
  padding: 0.75rem 0.625rem;
  font-size: 1.375rem;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.4);
  border-right: #bcbccb 0.0625rem solid;
}
#resources .right-panel .toggle-btn i {
  transform: rotate(0deg);
  transition: transform 0.15s ease;
}
#resources .right-panel.opened {
  right: 0;
}
#resources .right-panel.opened .toggle-btn i {
  transform: rotate(180deg);
}
#resources .right-panel.wide {
  width: calc(80% - 16.25rem);
}
#resources .right-panel.wide .wide-btn .fa.fa-arrow-left {
  left: 0.75rem;
}
#resources .right-panel.wide .wide-btn .fa.fa-arrow-right {
  left: -0.75rem;
}
#resources .row {
  min-height: 0.0625rem;
}
#resources h1 {
  font-size: 1.75rem;
  margin-bottom: 1.25rem;
}
#resources .search-filters {
  padding: 1.25rem;
  overflow: hidden;
}
#resources .search-filters .search-filters-content {
  overflow: auto;
}
#resources .search-filters .extent-btn {
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0.125rem 0;
}
#resources .search-filters .extent-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2;
}
#resources .search-filters .extent-backdrop.active {
  display: block;
}
#resources .search-filters .extent-backdrop p {
  text-align: center;
  position: absolute;
  left: 0;
  top: 28%;
  font-size: 0.875rem;
  margin-top: -8px;
  width: 100%;
}
#resources .search-filters .btn-clear-extent {
  position: absolute;
  top: -0.5rem;
  right: 0;
  font-size: 1.875rem;
  line-height: 1.25rem;
  z-index: 1;
  padding: 0.3125rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
#resources .search-filters .btn-clear-extent:hover, #resources .search-filters .btn-clear-extent:focus, #resources .search-filters .btn-clear-extent:active {
  opacity: 1;
}
#resources .search-filters .btn-clear-extent i {
  position: relative;
  top: -0.1875rem;
}
#resources .search-filters .rosace {
  position: relative;
  height: 4.6875rem;
  background: url("/assets/images/rosace.png") no-repeat scroll center center;
}
#resources .search-filters .rosace div {
  position: absolute;
  font-size: 0.75rem;
}
#resources .search-filters .rosace .north,
#resources .search-filters .rosace .south {
  left: 0;
  width: 100%;
  text-align: center;
}
#resources .search-filters .rosace .north {
  bottom: 50%;
  margin-bottom: 1.25rem;
}
#resources .search-filters .rosace .south {
  top: 50%;
  margin-top: 1.25rem;
}
#resources .search-filters .rosace .east,
#resources .search-filters .rosace .west {
  top: 50%;
  margin-top: -0.5rem;
}
#resources .search-filters .rosace .east {
  left: 50%;
  margin-left: 1.5625rem;
}
#resources .search-filters .rosace .west {
  right: 50%;
  margin-right: 1.5625rem;
}
#resources .results-table {
  position: absolute;
  padding: 1.25rem 0 0;
  background: white;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  transition: left 0.15s linear;
  overflow: hidden;
  z-index: 1;
}
#resources .results-table.opened {
  left: 0;
}
#resources .results-table h2 {
  padding-left: 1.25rem;
}
#resources .results-table .results-table-content {
  overflow: auto;
}
#resources .results-table .list-counter {
  margin: 0;
  padding: 0 1.25rem;
}
#resources .results-table .wide-btn {
  padding-left: 0.9375rem;
  padding-right: 1.25rem;
}
#resources .results-table .wide-btn .fa {
  font-size: 0.625rem;
  position: relative;
  top: -0.125rem;
  margin: 0;
}
#resources .results-table .wide-btn .fa.fa-arrow-left {
  margin-right: 0.1875rem;
}
#resources .results-table .wide-btn .fa.fa-arrow-right {
  margin-right: 0.625rem;
}
#resources .data-table tr td {
  position: relative;
}
#resources .data-table tr td:before, #resources .data-table tr td:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  border-top: transparent 0.1875rem solid;
}
#resources .data-table tr td:before {
  top: -0.125rem;
}
#resources .data-table tr td:after {
  bottom: -0.125rem;
}
#resources .data-table tr td:first-child {
  border-left: 0.625rem transparent solid;
}
#resources .data-table tr td:first-child:before, #resources .data-table tr td:first-child:after {
  left: -0.625rem;
  width: calc(100% + 0.625rem);
}
#resources .data-table tr.hovered td:before, #resources .data-table tr.hovered td:after {
  border-top-color: #e36e26;
}
#resources .data-table tr.hovered td:first-child {
  border-left-color: #e36e26;
}
#resources .data-table .name .fa {
  width: 0.9375rem;
  text-align: center;
  margin-right: 0.3125rem;
  color: #888;
}
#resources .data-table .type {
  width: 5.625rem;
}
#resources .data-table .date {
  width: 5.9375rem;
}
#resources .data-table .owner {
  width: 16.25rem;
}
#resources .left-ressources-panel,
#resources .right-ressources-panel {
  background: #ffffff;
}
#resources .left-ressources-panel.hidden,
#resources .right-ressources-panel.hidden {
  display: none;
}
#resources .left-ressources-panel {
  overflow-y: auto;
  position: relative;
}
#resources .left-resources-panel-content {
  padding: 1.3125rem 2.1875rem;
}
#resources .right-ressources-panel {
  overflow: hidden;
}

.resource-details {
  font-size: 0.875rem;
  margin: 0;
  width: 25rem;
}
.resource-details dl {
  margin: 0;
}
.resource-details dt,
.resource-details dd {
  padding: 0.1875rem 0.625rem;
  color: #585858;
}
.resource-details dt {
  background: #f5f6fa;
  border-top: 0.0625rem #e0e0e0 solid;
}
.resource-details dt:first-child {
  border-top: 0 none;
}
.resource-details dd:last-child {
  margin: 0;
}
.resource-details .project-extent {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.25rem;
  background: #ffffff;
  border: 0.0625rem #e0e0e0 solid;
  padding: 0 0.3125rem;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: color 0.3s ease;
}
.resource-details .project-extent:hover, .resource-details .project-extent:active, .resource-details .project-extent:focus {
  color: #e36e26;
}

.meta-detail h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  margin-top: 0;
}
.meta-detail .accordion-title {
  padding-bottom: 0.1875rem;
  margin-bottom: 0.1875rem;
  border-bottom: 0.125rem solid #e36e26;
}
.meta-detail .accordion-content {
  max-height: 18.75rem;
  overflow: auto;
  transition: max-height 0.3s linear;
}
.meta-detail .accordion-content.closed {
  max-height: 0;
}
.meta-detail .accordion-btn {
  padding: 0 0.625rem 0 0;
}
.meta-detail .accordion-btn span {
  font-size: 0.9375rem;
  font-weight: bold;
  color: #444;
}
.meta-detail .accordion-btn .fa {
  transform: rotate(0deg);
  transition: transform 0.3s linear, opacity 0.3s ease;
  opacity: 0.6;
}
.meta-detail .accordion-btn .fa.rotated {
  transform: rotate(180deg);
}
.meta-detail .accordion-btn:hover .fa, .meta-detail .accordion-btn:focus .fa, .meta-detail .accordion-btn:active .fa {
  opacity: 1;
}
.meta-detail .contacts {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.meta-detail .contacts li {
  margin-bottom: 5px;
}
.meta-detail .contacts li:last-child {
  margin-bottom: 0;
}

.rights-modal .modal-dialog {
  max-width: 90%;
  height: 85%;
}
.rights-modal .modal-dialog .modal-content,
.rights-modal .modal-dialog ng-component {
  height: 100%;
}
.rights-modal .modal-dialog .modal-body {
  overflow: auto;
}
.rights-modal .form-group {
  border-bottom: #bcbccb thin solid;
  padding-bottom: 0.9375rem;
}
.rights-modal .form-group:last-child {
  border-bottom: 0 none;
  padding-bottom: 0;
}
.rights-modal .form-control {
  padding: 0.125rem 0.3125rem;
  height: auto;
}
.rights-modal select.form-control {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}
.rights-modal .table .btn-cell,
.rights-modal .table .btn-cell-head {
  padding: 0;
}
.rights-modal .table .btn-cell .btn-icon {
  font-size: 1.875rem;
  line-height: 1.875rem;
  padding: 0.75rem 1.0625rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
.rights-modal .table .btn-cell .btn-icon:hover, .rights-modal .table .btn-cell .btn-icon:focus, .rights-modal .table .btn-cell .btn-icon:active {
  opacity: 1;
}
.rights-modal .table .btn-cell-head .btn-icon {
  padding: 0.625rem 1rem;
  color: #e36e26;
  transition: color 0.3s ease;
}
.rights-modal .table .btn-cell-head .btn-icon:hover, .rights-modal .table .btn-cell-head .btn-icon:focus, .rights-modal .table .btn-cell-head .btn-icon:active {
  color: #be5718;
}

#results-table-modal .p-tabview-nav {
  border-bottom: 1px solid #dee2e6;
}
#results-table-modal .p-tabview-nav li {
  border: 0 none;
  border-bottom: 0.125rem transparent solid;
  padding: 0.75rem 1.875rem;
  color: #666;
  margin-right: 0.1875rem;
}
#results-table-modal .p-tabview-nav li:focus, #results-table-modal .p-tabview-nav li:hover {
  border-color: #666;
}
#results-table-modal .p-tabview-nav li:active, #results-table-modal .p-tabview-nav li.p-highlight, #results-table-modal .p-tabview-nav li:active:hover, #results-table-modal .p-tabview-nav li:active:focus, #results-table-modal .p-tabview-nav li.p-highlight:hover, #results-table-modal .p-tabview-nav li.p-highlight:focus {
  color: #e36e26;
  border-color: #e36e26;
}
#results-table-modal .p-tabview-nav li a {
  text-decoration: none;
}

.edit-form {
  background: white;
  padding: 1.25rem;
  overflow: auto;
  max-height: 100%;
}
.edit-form .form-separator {
  margin: 0.9375rem 0 1.875rem;
  border-color: #bcbccb;
}
.edit-form .form-title {
  font-size: 1.5rem;
}

.edit-fields-form.ng-submitted .ng-invalid {
  border-color: #da2323;
}
.edit-fields-form.ng-submitted .ng-invalid .form-control {
  border-color: #da2323;
}
.edit-fields-form .ng-invalid {
  border-color: #bcbccb;
}
.edit-fields-form fieldset {
  position: relative;
  border: thin #bcbccb solid;
  padding: 0.9375rem;
  border-radius: 0.1875rem;
}
.edit-fields-form fieldset legend {
  position: absolute;
  font-size: 1rem;
  background: white;
  z-index: 1;
  width: auto;
  padding: 0 0.625rem;
  top: -0.8125rem;
  left: 0.625rem;
}
.edit-fields-form fieldset label {
  margin-right: 0.625rem;
}
.edit-fields-form fieldset.with-close-btn .btn-close {
  top: -0.5rem;
  right: -0.9375rem;
  background: white;
}
.edit-fields-form .form-group label small {
  display: block;
  color: #888;
}
.edit-fields-form .form-group .alert {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  z-index: 1;
}
.edit-fields-form .form-group .alert p:last-child {
  margin-bottom: 0;
}
.edit-fields-form .ui-calendar-button {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #e36e26;
  border-color: #e36e26;
  color: #fff;
  outline: 0;
  height: auto;
  border: none;
  border-radius: 0 0.25rem 0.25rem 0;
  position: relative;
}
.edit-fields-form .ui-calendar-button:disabled {
  background-color: #e36e26;
  border-color: #e36e26;
  color: #fff;
}
.edit-fields-form .ui-calendar-button:not(:disabled):focus, .edit-fields-form .ui-calendar-button:not(:disabled):hover {
  background-color: #be5718;
  border-color: #be5718;
  color: #fff;
}
.edit-fields-form .ui-calendar-button:not(:disabled):active, .edit-fields-form .ui-calendar-button:not(:disabled):active:focus, .edit-fields-form .ui-calendar-button:not(:disabled):active:hover {
  background-color: #be5718;
  border-color: #be5718;
  color: #fff;
  box-shadow: none;
}
.edit-fields-form .file-size-error {
  color: #da2323;
}
.edit-fields-form .ui-fileupload-buttonbar {
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  background-color: #e36e26;
  border-color: #e36e26;
  color: #fff;
  outline: 0;
  border-radius: 0.25rem;
}
.edit-fields-form .ui-fileupload-buttonbar:disabled {
  background-color: #e36e26;
  border-color: #e36e26;
  color: #fff;
}
.edit-fields-form .ui-fileupload-buttonbar:not(:disabled):focus, .edit-fields-form .ui-fileupload-buttonbar:not(:disabled):hover {
  background-color: #be5718;
  border-color: #be5718;
  color: #fff;
}
.edit-fields-form .ui-fileupload-buttonbar:not(:disabled):active, .edit-fields-form .ui-fileupload-buttonbar:not(:disabled):active:focus, .edit-fields-form .ui-fileupload-buttonbar:not(:disabled):active:hover {
  background-color: #be5718;
  border-color: #be5718;
  color: #fff;
  box-shadow: none;
}
.edit-fields-form .ui-fileupload-content {
  padding: 0 0 0 0.5rem;
}
.edit-fields-form .ui-fileupload-files {
  word-break: break-word;
}
.edit-fields-form .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  background: 0 none;
  padding: 0;
  text-align: center;
  width: 1.875rem;
  border: 0 none;
  color: #444;
}
.edit-fields-form .btn-close span {
  font-size: 1.875rem;
  line-height: 1.25rem;
  position: relative;
  top: -0.1875rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
.edit-fields-form .btn-close:hover span, .edit-fields-form .btn-close:focus span, .edit-fields-form .btn-close:active span {
  opacity: 1;
}
.edit-fields-form .btn-close.btn-row-delete {
  position: relative;
  top: 0.25rem;
  width: 100%;
}
.edit-fields-form .trosace {
  position: relative;
  height: 5.625rem;
  min-width: 9.0625rem;
  background: url("/assets/images/rosace.png") no-repeat center center;
}
.edit-fields-form .trosace span {
  position: absolute;
}
.edit-fields-form .trosace .tnorth,
.edit-fields-form .trosace .tsouth {
  width: 100%;
  left: 0;
  text-align: center;
}
.edit-fields-form .trosace .tnorth {
  top: 0;
}
.edit-fields-form .trosace .tsouth {
  bottom: 0;
}
.edit-fields-form .trosace .teast,
.edit-fields-form .trosace .twest {
  top: 50%;
  margin-top: -0.6875rem;
}
.edit-fields-form .trosace .teast {
  left: 50%;
  margin-left: 1.875rem;
}
.edit-fields-form .trosace .twest {
  right: 50%;
  margin-right: 1.875rem;
}
.edit-fields-form .described-extent .open-btn {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 0 0 20px 0;
}
.edit-fields-form .drop-files .drop-zone {
  cursor: pointer;
  border: #bcbccb 2px dashed;
  background: #f0f0f7;
  border-radius: 5px;
  height: 150px;
  width: 100%;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.edit-fields-form .drop-files .drop-zone p,
.edit-fields-form .drop-files .drop-zone .drop-icon {
  pointer-events: none;
}
.edit-fields-form .drop-files .drop-zone .drop-icon {
  border: 3px solid #bcbccb;
  background: white;
  border-radius: 5em;
  height: 5em;
  width: 5em;
}
.edit-fields-form .drop-files .drop-zone .fa {
  font-size: 2.5em;
  position: relative;
  top: -3px;
}
.edit-fields-form .drop-files .drop-zone.dragover {
  background-color: white;
  border-color: #e36e26;
}
.edit-fields-form .drop-files .file-list {
  list-style-type: none;
  padding: 0;
  margin: 0.625rem 0 0;
}
.edit-fields-form .drop-files .file-list li {
  border-bottom: thin solid #bcbccb;
  padding: 0 0 0 10px;
}
.edit-fields-form .drop-files .file-list li:last-child {
  border-bottom: 0 none;
}
.edit-fields-form .drop-files .file-list li.invalid {
  color: #da2323;
}
.edit-fields-form .drop-files .file-list li button {
  font-size: 2em;
  line-height: 0.6em;
  padding: 8px 10px;
}

.admin-layout {
  background: white;
  box-shadow: 0 0 0.1875rem rgba(100, 100, 100, 0.1);
}
.admin-layout .admin-nav {
  background: #43425d;
}
.admin-layout .admin-nav .navbar-brand,
.admin-layout .admin-nav .nav-link {
  color: #fff;
}
.admin-layout .admin-nav .nav-link {
  padding: 1rem 1rem 0.625rem;
  border-bottom: 0.375rem transparent solid;
  transition: background-color 0.3s ease, border-bottom 0.3s ease;
  color: #fff;
}
.admin-layout .admin-nav .nav-link:hover, .admin-layout .admin-nav .nav-link:active, .admin-layout .admin-nav .nav-link:focus, .admin-layout .admin-nav .nav-link.active {
  color: #fff;
  text-decoration: none;
  background-color: #56556e;
  border-bottom-color: #e36e26;
}
.admin-layout .status-dot {
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  background: #da2323;
  border-radius: 50%;
}
.admin-layout .status-dot.active {
  background: #00ad0d;
}
.admin-layout .admin-page > ng-component {
  height: 100%;
}
.admin-layout .admin-page .page-header {
  padding: 0.9375rem 1.25rem 0;
}
.admin-layout .admin-page .page-content {
  padding: 0 1.25rem 1.25rem;
}
.admin-layout .admin-page .page-content .data-table {
  overflow: auto;
  max-height: 100%;
}

#admin-group-manage h2,
#user-search-admin h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}
#admin-group-manage .group-content,
#user-search-admin .group-content {
  max-width: 43.75rem;
}
#admin-group-manage .user-list,
#user-search-admin .user-list {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: auto;
}
#admin-group-manage .user-list li .btn-icon,
#user-search-admin .user-list li .btn-icon {
  font-size: 1.875rem;
  line-height: 1.25rem;
  padding: 0.625rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
#admin-group-manage .user-list li .btn-icon:hover, #admin-group-manage .user-list li .btn-icon:focus, #admin-group-manage .user-list li .btn-icon:active,
#user-search-admin .user-list li .btn-icon:hover,
#user-search-admin .user-list li .btn-icon:focus,
#user-search-admin .user-list li .btn-icon:active {
  opacity: 1;
}

#admin-group-edit .edit-form {
  max-width: 37.5rem;
}