#project-detail {
  h1 {
    margin-bottom: calculateRem(10px);
    .fa {
      color: $projectIconColor;
      margin-right: calculateRem(10px);
      font-size: 0.9em;
    }
  }
  .description {
    color: $dataTableHeaderColor;
    font-size: calculateRem(13px);
    margin: 0;
  }
  .subcontent {
    padding: 0;
  }
  .p-tabview-panels {
    border-color: $tabContentBorder;
    padding: 0;
    position: relative;
    height: 100%;
    .tab-pane {
      height: 100%;
    }
    .add-btn {
      position: absolute;
      bottom: 100%;
      right: calculateRem(7px);
      padding: calculateRem(5px) calculateRem(25px);
      margin-bottom: calculateRem(7px);
    }
  }
  .p-tabview-nav {
    border-bottom: 1px solid #dee2e6;
    li {

      border: 0 none;
      border-bottom: calculateRem(2px) transparent solid;
      padding: calculateRem(12px) calculateRem(30px);
      color: $tabItemColor;
      margin-right: calculateRem(3px);

      &:focus,
      &:hover {
        border-color: $tabItemColor;
      }

      &:active,
      &.p-highlight,
      &:active:hover,
      &:active:focus,
      &.p-highlight:hover,
      &.p-highlight:focus {
        color: $themeColor;
        border-color: $themeColor;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .data-table {
    margin: 0 -1rem;
    overflow: auto;
    .actions {
      width: calculateRem(50px);
    }
    .url {
      width: calculateRem(350px);
      a {
        display: block;
        width: calculateRem(326px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .title-button-container {
    button {
      white-space: nowrap;
    }
  }
  .meta-detail {
    overflow: auto;
  }
}
