.breadcrumb {
  background-color: $commonBg;
  margin: 0;
  padding-left: calculateRem(35px);
  padding-right: calculateRem(35px);
  border-radius: 0;
  box-shadow: $defaultShadow;
  position: relative;
  z-index: 10;
  .breadcrumb-item {
    font-size: calculateRem(13px);
    &:before,
    &.active {
      color: $breadcrumbActiveItemColor;
    }
  }
}
