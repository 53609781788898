@font-face {
  font-family: 'multicons';
  src:  url('../assets/fonts/multicons.eot?in0sj');
  src:  url('../assets/fonts/multicons.eot?in0sj#iefix') format('embedded-opentype'),
    url('../assets/fonts/multicons.ttf?in0sj') format('truetype'),
    url('../assets/fonts/multicons.woff?in0sj') format('woff'),
    url('../assets/fonts/multicons.svg?in0sj#multicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.mult {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'multicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mult-alea:before {
  content: "\e900";
}
